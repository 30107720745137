import React, { useRef } from 'react';
import { Col, Row, Container, Button, Ratio } from 'react-bootstrap';

const VideoQuizGame = ({ quiz, onClose }) => {
  const videoRef = useRef(null);

  const videoUrl = `https://kursy.glon.org/video/${quiz.data.videoFile}`;

  return (
    <Container className="bg-secondary">
      <Row>
        <Col>
          <h2>{quiz.name}</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <div className="video-container mb-3">
            <Ratio aspectRatio="16x9">
              <video
                ref={videoRef}
                controls
                style={{ width: '100%', height: 'auto' }}
                src={videoUrl}
              >
                Twoja przeglądarka nie obsługuje odtwarzania wideo.
              </video>
            </Ratio>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={onClose} className="mt-3">
            Zakończ
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default VideoQuizGame;
