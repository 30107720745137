import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import LoadingSpinner from './LoadingSpinner';

const UserCourses = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserCourses = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/user/courses');
        setCourses(response.data);
      } catch (error) {
        console.error('Failed to fetch user courses:', error);
        setError('Nie udało się pobrać kursów. Spróbuj ponownie później.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserCourses();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }


return (
  <div className="container mt-4">
    <h2>Moje kursy</h2>
    {courses.length === 0 ? (
      <p>Nie masz jeszcze dostępu do żadnych kursów.</p>
    ) : (
      courses.map(course => (
        <div key={course.id} className="row mb-4">
          <div className="col-12">
            <div className="card">
              <div className="row no-gutters">
                {course.image_url && (
                  <div className="col-md-4">
                    <img src={course.image_url} className="card-img" alt={course.title} />
                  </div>
                )}
                <div className="col-md-8">
                  <div className="card-body">
                    <h5 className="card-title">{course.title}</h5>
     <div
              className="ql-editor bg-light p-3 rounded"
              dangerouslySetInnerHTML={{ __html: course.description }}
            />
                    <Link to={`/my-courses/${course.id}`} className="btn btn-primary">
                      Przejdź do kursu
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))
    )}
  </div>
);

};

export default UserCourses;
