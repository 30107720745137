import React from 'react';
import QuillEditor from './QuillEditor';

const LessonForm = ({ lesson, handleChange, handleContentChange, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-between align-items-center my-4">
        <h2>{lesson.id ? 'Edytuj lekcję' : 'Stwórz nową lekcję'}</h2>
        <button type="submit" className="btn btn-primary">Zapisz</button>
      </div>
      <div className="mb-3">
        <label htmlFor="title" className="form-label">Tytuł</label>
        <input
          type="text"
          className="form-control"
          id="title"
          name="title"
          value={lesson.title}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-4 py-4">
        <label htmlFor="content" className="form-label">Treść</label>
        <QuillEditor
          value={lesson.content}
          onChange={handleContentChange}
          rows={20}
        />
      </div>
    </form>
  );
};

export default LessonForm;
