import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';

const API_URL = 'https://kursy.glon.org/quiz/api/';

const CategoryQuizGame = ({ quizId, onClose }) => {
  const [quiz, setQuiz] = useState(null);
  const [words, setWords] = useState([]);
  const [categoryBuckets, setCategoryBuckets] = useState({});
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchQuiz = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}?id=${quizId}`);
        setQuiz(response.data);
        initializeQuiz(response.data);
      } catch (error) {
        console.error('Error fetching quiz:', error);
        setError('Nie udało się pobrać danych quizu.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuiz();
  }, [quizId]);

  const initializeQuiz = (quizData) => {
    if (quizData && quizData.data) {
      const allWords = quizData.data.flatMap(cat =>
        cat.words.map(word => ({ id: word, content: word, category: cat.name }))
      );
      setWords(shuffleArray(allWords));
      setCategoryBuckets(
        quizData.data.reduce((acc, cat) => {
          acc[cat.name] = [];
          return acc;
        }, {})
      );
      setChecked(false);
    }
  };

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === 'wordsList') {
      const newWords = Array.from(words);
      const [reorderedItem] = newWords.splice(source.index, 1);

      if (destination.droppableId === 'wordsList') {
        newWords.splice(destination.index, 0, reorderedItem);
        setWords(newWords);
      } else {
        setCategoryBuckets(prev => ({
          ...prev,
          [destination.droppableId]: [...prev[destination.droppableId], reorderedItem]
        }));
        setWords(newWords);
      }
    } else {
      const sourceBucket = Array.from(categoryBuckets[source.droppableId]);
      const [reorderedItem] = sourceBucket.splice(source.index, 1);

      if (destination.droppableId === 'wordsList') {
        setWords(prev => [...prev, reorderedItem]);
        setCategoryBuckets(prev => ({
          ...prev,
          [source.droppableId]: sourceBucket
        }));
      } else if (source.droppableId !== destination.droppableId) {
        const destBucket = Array.from(categoryBuckets[destination.droppableId]);
        destBucket.splice(destination.index, 0, reorderedItem);
        setCategoryBuckets(prev => ({
          ...prev,
          [source.droppableId]: sourceBucket,
          [destination.droppableId]: destBucket
        }));
      } else {
        sourceBucket.splice(destination.index, 0, reorderedItem);
        setCategoryBuckets(prev => ({
          ...prev,
          [source.droppableId]: sourceBucket
        }));
      }
    }
  };

  const checkAnswers = () => {
    setChecked(true);
  };

  if (isLoading) {
    return <Alert variant="info">Ładowanie quizu...</Alert>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!quiz) {
    return <Alert variant="warning">Nie znaleziono quizu.</Alert>;
  }

  return (
    <Container fluid>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row>
          <Col md={3}>
            <h4>Dopasuj kategorie</h4>
            <Droppable droppableId="wordsList">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: '300px', backgroundColor: '#f8f9fa', padding: '10px' }}>
                  {words.map((word, index) => (
                    <Draggable key={word.id} draggableId={word.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`p-2 mb-2 border ${snapshot.isDragging ? 'bg-secondary text-white' : 'bg-light'}`}
                        >
                          {word.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Col>
          <Col md={9}>
            <Row>
              {Object.entries(categoryBuckets).map(([category, words]) => (
                <Col md={4} key={category}>
                  <h4>{category}</h4>
                  <Droppable droppableId={category}>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: '300px', backgroundColor: '#e9ecef', padding: '10px' }}>
                        {words.map((word, index) => (
                          <Draggable key={word.id} draggableId={word.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`p-2 mb-2 border ${
                                  checked
                                    ? word.category === category
                                      ? 'bg-primary text-white'
                                      : 'bg-danger text-white'
                                    : snapshot.isDragging
                                      ? 'bg-secondary text-white'
                                      : 'bg-light text-dark'
                                }`}
                              >
                                {word.content}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </DragDropContext>
      <Button onClick={checkAnswers} disabled={checked} className="mx-2 mt-3 mr-2">
        Sprawdź
      </Button>
      <Button onClick={onClose} className="mx-2 mt-3">
        Zakończ
      </Button>
    </Container>
  );
};

export default CategoryQuizGame;
