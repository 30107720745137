import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import LessonForm from './LessonForm';

const CreateLesson = () => {
  const [lesson, setLesson] = useState({ title: '', content: '', image_url: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLesson(prevLesson => ({ ...prevLesson, [name]: value }));
  };

  const handleContentChange = (content) => {
    setLesson(prevLesson => ({ ...prevLesson, content: content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/lessons', lesson);
      navigate(`/lessons/${response.data.id}`);
    } catch (error) {
      console.error('Failed to create lesson:', error);
    }
  };

  return (
    <div className="container mt-4 bg-light">
      <LessonForm
        lesson={lesson}
        handleChange={handleChange}
        handleContentChange={handleContentChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default CreateLesson;
