import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mt-4">
      <h2>Polityka Prywatności</h2>
      <p>Ostatnia aktualizacja: 17 lipca 2024</p>
      <h3>1. Zbieranie danych</h3>
      <p>Zbieramy tylko te dane, które są niezbędne do funkcjonowania naszej platformy edukacyjnej. Obejmuje to:</p>
      <ul>
        <li>Dane konta (imię, nazwisko, adres e-mail)</li>
        <li>Informacje o postępach w nauce</li>
        <li>Dane dotyczące płatności (przetwarzane przez zewnętrznego dostawcę usług płatniczych)</li>
      </ul>
      <h3>2. Wykorzystanie danych</h3>
      <p>Wykorzystujemy zebrane dane w celu:</p>
      <ul>
        <li>Dostarczania i ulepszania naszych usług edukacyjnych</li>
        <li>Komunikacji z użytkownikami</li>
        <li>Personalizacji doświadczeń użytkowników</li>
      </ul>
      <h3>3. Ochrona danych</h3>
      <p>Stosujemy odpowiednie środki techniczne i organizacyjne, aby chronić dane użytkowników przed nieautoryzowanym dostępem, utratą lub zniszczeniem.</p>
      <h3>4. Prawa użytkowników</h3>
      <p>Użytkownicy mają prawo do:</p>
      <ul>
        <li>Dostępu do swoich danych</li>
        <li>Poprawiania swoich danych</li>
        <li>Usunięcia swoich danych</li>
        <li>Ograniczenia przetwarzania swoich danych</li>
      </ul>
      <p>W celu skorzystania z tych praw, prosimy o kontakt na adres: privacy@example.com</p>
    </div>
  );
};

export default PrivacyPolicy;
