import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

const SentenceQuizCreator = ({ initialQuiz, onSave, onCancel, isNameUnique }) => {
  const [quizId, setQuizId] = useState(null);
  const [quizName, setQuizName] = useState('');
  const [sentenceWithGaps, setSentenceWithGaps] = useState('');
  const [correctWords, setCorrectWords] = useState('');
  const [incorrectWords, setIncorrectWords] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (initialQuiz) {
      setQuizId(initialQuiz.id || null);
      setQuizName(initialQuiz.name || '');
      if (initialQuiz.data && initialQuiz.data.length > 0) {
        setSentenceWithGaps(initialQuiz.data[0].text || '');
        setCorrectWords(initialQuiz.data[0].correctWords.join('\n') || '');
        setIncorrectWords(initialQuiz.data[0].incorrectWords.join('\n') || '');
      }
    }
  }, [initialQuiz]);

  const handleSave = () => {
    setError('');

    if (!quizName.trim()) {
      setError("Nazwa jest wymagana.");
      return;
    }

    if (!isNameUnique(quizName, quizId)) {
      setError("Quiz o takiej nazwie już istnieje. Wybierz inną nazwę.");
      return;
    }

    const gapsMatch = sentenceWithGaps.match(/@\d+/g);
    const gapsCount = gapsMatch ? gapsMatch.length : 0;
    const correctWordsCount = correctWords.split('\n').filter(word => word.trim()).length;

    if (gapsCount !== correctWordsCount) {
      setError(`Liczba luk (${gapsCount}) nie zgadza się z liczbą poprawnych słów (${correctWordsCount}).`);
      return;
    }

    const quizData = {
      id: quizId,
      type: "sentence",
      name: quizName,
      data: [{
        text: sentenceWithGaps,
        correctWords: correctWords.split('\n').filter(word => word.trim()),
        incorrectWords: incorrectWords.split('\n').filter(word => word.trim())
      }]
    };

    onSave(quizData);
  };

  return (
    <Container className="p-3 bg-secondary-subtle">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        <Form.Group className="my-2">
          <Form.Label>Nazwa testu</Form.Label>
          <Form.Control
            type="text"
            value={quizName}
            onChange={(e) => setQuizName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group className="my-2">
          <Form.Label>Zdanie z lukami (użyj @1, @2, itd. dla oznaczenia luk)</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={sentenceWithGaps}
            onChange={(e) => setSentenceWithGaps(e.target.value)}
            required
          />
        </Form.Group>
        <Row>
          <Col md={6} className="my-2">
            <Form.Group>
              <Form.Label>Poprawne słowa (każde w nowej linii, w kolejności luk)</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={correctWords}
                onChange={(e) => setCorrectWords(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6} className="my-2">
            <Form.Group>
              <Form.Label>Niepoprawne słowa (każde w nowej linii)</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={incorrectWords}
                onChange={(e) => setIncorrectWords(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" onClick={handleSave} className="m-2">
          Zapisz
        </Button>
        <Button variant="secondary" onClick={onCancel} className="m-2">
          Anuluj
        </Button>
      </Form>
    </Container>
  );
};

export default SentenceQuizCreator;
