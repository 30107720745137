import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { quizapi } from '../services/api';

const QuizListModal = ({ show, onHide, onSelectQuiz, message, onClearMessage }) => {
  const [quizzes, setQuizzes] = useState([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);
  const [filterType, setFilterType] = useState('all');

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const response = await quizapi.get('/');
        setQuizzes(response.data);
        setFilteredQuizzes(response.data);
      } catch (error) {
        console.error('Error fetching quizzes:', error);
      }
    };

    if (show) {
      fetchQuizzes();
    }
  }, [show]);

  useEffect(() => {
    if (filterType === 'all') {
      setFilteredQuizzes(quizzes);
    } else {
      setFilteredQuizzes(quizzes.filter(quiz => quiz.type === filterType));
    }
  }, [filterType, quizzes]);

  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleClose = () => {
    onClearMessage();
    onHide();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Select a Quiz</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message && (
          <Alert variant="info" onClose={onClearMessage} dismissible>
            {message}
          </Alert>
        )}
        <Form.Group className="mb-3">
          <Form.Label>Filter by type:</Form.Label>
          <Form.Select onChange={handleFilterChange} value={filterType}>
            <option value="all">All</option>
            <option value="translation">Translation</option>
            <option value="category">Category</option>
            <option value="sentence">Sentence</option>
            <option value="multipleChoice">Multiple Choice</option>
            <option value="video">Video</option>
          </Form.Select>
        </Form.Group>
        <ul className="list-group">
          {filteredQuizzes.map(quiz => (
            <li key={quiz.id} className="list-group-item d-flex justify-content-between align-items-center">
              {quiz.name}
              <span className="badge bg-primary rounded-pill">{quiz.type}</span>
              <Button variant="outline-primary" size="sm" onClick={() => onSelectQuiz(quiz)}>
                Select
              </Button>
            </li>
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default QuizListModal;
