import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; 
import Home from './components/Home';
import Login from './components/Login';
import Courses from './components/Courses';
import CourseDetails from './components/CourseDetails';
import EditCourse from './components/EditCourse';
import CreateCourse from './components/CreateCourse';
import EditLesson from './components/EditLesson';
import CreateLesson from './components/CreateLesson';
import Lessons from './components/Lessons';
import LessonDetails from './components/LessonDetails';
import LessonView from './components/LessonView';
import UserProfile from './components/UserProfile';
import ImageManager from './components/ImageManager';
import VideoManager from './components/VideoManager';
import OrderManagement from './components/OrderManagement';
import UserCourses from './components/UserCourses';
import UserCourseDetails from './components/UserCourseDetails';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LoadingSpinner from './components/LoadingSpinner';
import TeacherDashboard from './components/TeacherDashboard';
import api from './services/api';
import BodyBackground from './components/BodyBackground';
import backgroundImage from './assets/pb-bg3.jpg';
import Contact from './components/Contact';
import Help from './components/Help';
import PrivacyPolicy from './components/PrivacyPolicy';
import Content from './components/Content';


function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

function AppContent() {
  const { isAuthenticated, isLoading, user, setUser } = useAuth();
  const [isCheckingRole, setIsCheckingRole] = useState(true);

  useEffect(() => {
    const checkUserRole = async () => {
      if (isAuthenticated && user && !user.role) {
        try {
          const response = await api.get('/user/role');
          setUser(prevUser => ({ ...prevUser, role: response.data.role }));
        } catch (error) {
          console.error('Error checking user role:', error);
        }
      }
      setIsCheckingRole(false);
    };

    checkUserRole();
  }, [isAuthenticated, user, setUser]);

  if (isLoading || isCheckingRole) {
    return <LoadingSpinner />;
  }

  const PrivateRoute = ({ element: Element, roles, ...rest }) => {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }

    if (roles && user && !roles.includes(user.role)) {
      return <div className="alert alert-warning">Brak uprawnień do wyświetlenia tej strony.</div>;
    }

    return <Element {...rest} user={user} />;
  };

  return (
    <div className="App d-flex flex-column min-vh-100">
      <BodyBackground imageUrl={backgroundImage} />
      <Navbar />
      <div className="container mt-4 flex-grow-1">
        <Routes>
          <Route path="/login" element={isAuthenticated ? <Navigate replace to="/" /> : <Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<PrivateRoute element={Courses} />} />
          <Route path="/my-courses" element={<PrivateRoute element={UserCourses} roles={['student']} />} />
          <Route path="/my-courses/:id" element={<PrivateRoute element={UserCourseDetails} roles={['student']} />} />
          <Route path="/courses/:id" element={<PrivateRoute element={CourseDetails} />} />
          <Route path="/courses/:id/edit" element={<PrivateRoute element={EditCourse} roles={['teacher']} />} />
          <Route path="/lessons" element={<PrivateRoute element={Lessons} roles={['teacher']} />} />
          <Route path="/lessons/:id" element={<PrivateRoute element={LessonDetails} roles={['teacher']} />} />
          <Route path="/lessons/:id/edit" element={<PrivateRoute element={EditLesson} roles={['teacher']} />} />
          <Route path="/lessons/:id/view" element={<PrivateRoute element={LessonView} roles={['student']} />} />
          <Route path="/profile" element={<PrivateRoute element={UserProfile} />} />
          <Route path="/images" element={<PrivateRoute element={ImageManager} roles={['teacher']} />} />
          <Route path="/videos" element={<PrivateRoute element={VideoManager} roles={['teacher']} />} />
          <Route path="/order-management" element={<PrivateRoute element={OrderManagement} roles={['teacher']} />} />
          <Route path="/teacher-dashboard" element={<PrivateRoute element={TeacherDashboard} roles={['teacher']} />} />
          <Route path="/courses/create" element={<PrivateRoute element={CreateCourse} roles={['teacher']} />} />
          <Route path="/lessons/create" element={<PrivateRoute element={CreateLesson} roles={['teacher']} />} />
          <Route path="/content" element={<PrivateRoute element={Content} roles={['teacher']} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/help" element={<Help />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </div>
      <Footer /> {/* Add the Footer component here */}
    </div>
  );
}

export default App;
