import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import LoadingSpinner from './LoadingSpinner';

const Lessons = ({user}) => {
  const [lessons, setLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/lessons');
        setLessons(response.data);
      } catch (error) {
        console.error('Failed to fetch lessons:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchLessons();
  }, []);

  const handleDeleteLesson = async (lessonId) => {
    if (window.confirm('Czy na pewno chcesz usunąć tę lekcję?')) {
      try {
        await api.delete(`/lessons/${lessonId}`);
        setLessons(lessons.filter(lesson => lesson.id !== lessonId));
      } catch (error) {
        console.error('Failed to delete lesson:', error);
      }
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Lekcje</h2>
        {user && user.role === 'teacher' && (
          <Link to="/lessons/create" className="btn btn-primary">Nowa lekcja</Link>
        )}
      </div>
      <div className="list-group">
        {lessons.map(lesson => (
          <div key={lesson.id} className="list-group-item">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5 className="mb-0">{lesson.title}</h5>
              {user && user.role === 'teacher' && (
                <button
                  onClick={() => handleDeleteLesson(lesson.id)}
                  className="btn btn-danger btn-sm"
                >
                  Usuń lekcję
                </button>
              )}
            </div>
            <Link to={`/lessons/${lesson.id}`} className="text-decoration-none">
              <div className="row">
                {lesson.image_url && (
                  <div className="col-md-3">
                    <img
                      src={lesson.image_url}
                      className="img-fluid rounded"
                      alt={lesson.title}
                    />
                  </div>
                )}
                <div className={lesson.image_url ? "col-md-9" : "col-md-12"}>
                  <div
                    className="ql-editor bg-light p-3 rounded"
                    dangerouslySetInnerHTML={{ __html: lesson.content }}
                  />
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Lessons;
