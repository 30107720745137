import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { BookOpen, Video, FileText, Image, List, ShoppingCart } from 'lucide-react';

const Dashboard = () => {
  const managementItems = [
    { title: 'Zarządzanie kursami', icon: <BookOpen size={34} />, link: '/courses' },
    { title: 'Zarządzanie lekcjami', icon: <FileText size={34} />, link: '/lessons' },
    { title: 'Grafika', icon: <Image size={34} />, link: '/images' },
    { title: 'Video', icon: <Video size={34} />, link: '/videos' },
    //{ title: 'Treści do lekcji – testy, filmy', icon: <List size={34} />, link: 'https://kursy.glon.org/quiz' },
    { title: 'Treści do lekcji – testy, filmy', icon: <List size={34} />, link: '/content' },
    { title: 'Zarządzanie zamówieniami', icon: <ShoppingCart size={34} />, link: '/order-management' },
  ];

  return (
    <Container className="mt-4">
      <h1 className="mb-4">Zarządzanie</h1>
      <Row>
        {managementItems.map((item, index) => (
          <Col key={index} xs={12} md={6} lg={3} className="mb-4">
            <ManagementCard {...item} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const ManagementCard = ({ title, icon, link }) => (
  <Card className="py-4 h-100 text-center">
    <Card.Body className="d-flex flex-column justify-content-between">
      <div>
        <Card.Title><h4>{title}</h4></Card.Title>
      </div>
      {link.startsWith('http') ? (
        <a
          href={link}
          className="btn btn-primary mt-3"
          target="_parent"
          rel="noopener noreferrer"
        >
          {icon}
        </a>
      ) : (
        <Link
          to={link}
          className="btn btn-primary mt-3"
        >
          {icon}
        </Link>
      )}
    </Card.Body>
  </Card>
);

export default Dashboard;
