import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';

const Navbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchUserRole = async () => {
      if (isAuthenticated) {
        try {
          const response = await api.get('/user');
          setUserRole(response.data.role);
        } catch (error) {
          console.error('Error fetching user role:', error);
        }
      } else {
        setUserRole(null);
      }
    };

    fetchUserRole();
  }, [isAuthenticated]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const navbarClass = userRole === 'teacher'
    ? "navbar navbar-expand-lg navbar-dark bg-dark"
    : "navbar navbar-expand-lg navbar-light bg-light";

  const navbarRole = userRole === 'teacher' ? " / nauczyciel" : "";

  return (
    <nav className={navbarClass}>
      <div className="container">
        <Link className="navbar-brand" to="/">Platforma Edukacyjna{navbarRole}</Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNavbar}
          aria-expanded={isOpen}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/courses" onClick={() => setIsOpen(false)}>Kursy</Link>
            </li>
            {isAuthenticated && (
              <>
                {userRole === 'student' && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/my-courses" onClick={() => setIsOpen(false)}>Moje kursy</Link>
                  </li>
                )}
                {userRole === 'teacher' && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/teacher-dashboard" onClick={() => setIsOpen(false)}>Zarządzanie</Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link className="nav-link" to="/profile" onClick={() => setIsOpen(false)}>Profil</Link>
                </li>
                <li className="nav-item">
                  <button className="nav-link btn btn-link" onClick={() => {logout(); setIsOpen(false);}}>Wyloguj</button>
                </li>
              </>
            )}
            {!isAuthenticated && (
              <li className="nav-item">
                <Link className="nav-link" to="/login" onClick={() => setIsOpen(false)}>Zaloguj</Link>
              </li>
            )}
            <li className="nav-item">
              <Link className="nav-link" to="/help" onClick={() => setIsOpen(false)}>Pomoc</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" onClick={() => setIsOpen(false)}>Kontakt</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
