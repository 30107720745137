import React, { useEffect, useState } from 'react';
import './BodyBackground.css';

const BodyBackground = ({
  imageUrl,
  backgroundWidth = 'auto',
  backgroundHeight = 'auto'
}) => {
  const [backgroundSize, setBackgroundSize] = useState(`${backgroundWidth} ${backgroundHeight}`);

  useEffect(() => {
    const originalBackground = document.body.style.background;

    const updateBackgroundSize = () => {
      let width = backgroundWidth;
      let height = backgroundHeight;

      // Konwertuj liczby na stringi z 'px'
      if (typeof backgroundWidth === 'number') width = `${backgroundWidth}px`;
      if (typeof backgroundHeight === 'number') height = `${backgroundHeight}px`;

      setBackgroundSize(`${width} ${height}`);
    };

    updateBackgroundSize();

    document.body.style.background = `url(${imageUrl}) repeat`;
    document.body.style.backgroundSize = backgroundSize;

    const handleScroll = () => {
      const scrollY = window.scrollY;
      document.body.style.backgroundPosition = `center ${scrollY * 0.5}px`;
    };

    window.addEventListener('scroll', handleScroll);

    const lightOverlay = document.createElement('div');
    lightOverlay.style.position = 'fixed';
    lightOverlay.style.top = '0';
    lightOverlay.style.left = '0';
    lightOverlay.style.width = '100%';
    lightOverlay.style.height = '100%';
    lightOverlay.style.backgroundColor = 'rgba(255, 255, 255, 0.65)';
    lightOverlay.style.zIndex = '-1';
    lightOverlay.style.pointerEvents = 'none';

    document.body.appendChild(lightOverlay);

    return () => {
      document.body.style.background = originalBackground;
      document.body.removeChild(lightOverlay);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [imageUrl, backgroundWidth, backgroundHeight, backgroundSize]);

  return null;
};

export default BodyBackground;
