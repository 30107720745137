import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

const MultipleChoiceQuizCreator = ({ initialQuiz, onSave, onCancel, isNameUnique }) => {
  const [quizId, setQuizId] = useState(null);
  const [quizName, setQuizName] = useState('');
  const [questions, setQuestions] = useState([{ 
    question: '', 
    correctAnswer: '',
    incorrectAnswers: ''
  }]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (initialQuiz) {
      setQuizId(initialQuiz.id || null);
      setQuizName(initialQuiz.name || '');
      if (initialQuiz.data && initialQuiz.data.length > 0) {
        setQuestions(initialQuiz.data.map(q => ({
          question: q.question,
          correctAnswer: q.correctAnswer,
          incorrectAnswers: q.incorrectAnswers.join('\n')
        })));
      }
    }
  }, [initialQuiz]);

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...questions];
    newQuestions[index][field] = value;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { question: '', correctAnswer: '', incorrectAnswers: '' }]);
  };

  const removeQuestion = (index) => {
    if (questions.length > 1) {
      setQuestions(questions.filter((_, i) => i !== index));
    }
  };

  const handleSave = () => {
    setError('');

    if (!quizName.trim()) {
      setError("Nazwa jest wymagana.");
      return;
    }

    if (!isNameUnique(quizName, quizId)) {
      setError("Taka nazwa już istnieje. Wybierz inną.");
      return;
    }

    const quizData = {
      id: quizId,
      type: "multipleChoice",
      name: quizName,
      data: questions.map(q => ({
        question: q.question.trim(),
        correctAnswer: q.correctAnswer.trim(),
        incorrectAnswers: q.incorrectAnswers.split('\n').map(a => a.trim()).filter(a => a !== '')
      }))
    };

    onSave(quizData);
  };

  return (
    <Container className="bg-secondary-subtle p-4">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        <Form.Group className="my-2">
          <Form.Label>Nazwa</Form.Label>
          <Form.Control
            type="text"
            value={quizName}
            onChange={(e) => setQuizName(e.target.value)}
            required
          />
        </Form.Group>
        {questions.map((q, index) => (
          <div key={index} className="mb-4 p-3 border rounded">
		<hr/>
<Row><Col md={6}>
            <Form.Group>
              <Form.Label>Pytanie</Form.Label>
              <Form.Control
                type="text"
                value={q.question}
                onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                required
              />
            </Form.Group>
</Col><Col md={6}>
		<Button variant="danger" className="my-2" onClick={() => removeQuestion(index)}>Usuń pytanie</Button>
</Col></Row>
<Row><Col md={6}>
            <Form.Group>
              <Form.Label>Poprawna odpowiedź</Form.Label>
              <Form.Control
                type="text"
                value={q.correctAnswer}
                onChange={(e) => handleQuestionChange(index, 'correctAnswer', e.target.value)}
                required
              />
            </Form.Group>
</Col>
<Col md={6}>
            <Form.Group>
              <Form.Label>Niepoprawne odpowiedzi (każda w nowej linii)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={q.incorrectAnswers}
                onChange={(e) => handleQuestionChange(index, 'incorrectAnswers', e.target.value)}
                required
              />
            </Form.Group>
</Col></Row>
	{/*<Button variant="danger" className="my-2" onClick={() => removeQuestion(index)}>Usuń pytanie</Button>*/}
          </div>
        ))}
        <Button onClick={addQuestion} className="mb-3 mx-2">Dodaj pytanie</Button>
	  <hr/>
        <div>
          <Button variant="primary" onClick={handleSave}  className="m-2">
            Zapisz test
          </Button>
          <Button variant="secondary" onClick={onCancel} className="m-2">
            Anuluj
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default MultipleChoiceQuizCreator;
