import React, { useState, useEffect } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';

const MultipleChoiceQuizGame = ({ quiz, onClose }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [shuffledAnswers, setShuffledAnswers] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [score, setScore] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!quiz || !quiz.data || quiz.data.length === 0) {
      setError("Nieprawidłowe dane quizu");
      return;
    }
    shuffleAnswers(currentQuestionIndex);
  }, [quiz, currentQuestionIndex]);

  const shuffleAnswers = (index) => {
    if (!quiz || !quiz.data[index]) return;
    const currentQuestion = quiz.data[index];
    const allAnswers = [currentQuestion.correctAnswer, ...currentQuestion.incorrectAnswers];
    setShuffledAnswers(shuffleArray(allAnswers));
  };

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleAnswer = (answer) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestionIndex] = answer;
    setUserAnswers(newUserAnswers);
  };

  const handleNext = () => {
    if (currentQuestionIndex < quiz.data.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    } else {
      finishQuiz();
    }
  };

  const finishQuiz = () => {
    let correctCount = 0;
    quiz.data.forEach((question, index) => {
      if (userAnswers[index] === question.correctAnswer) {
        correctCount++;
      }
    });
    setScore(correctCount);
    setIsFinished(true);
  };

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!quiz || !quiz.data || currentQuestionIndex >= quiz.data.length) {
    return <Alert variant="warning">Brak danych quizu lub quiz jest pusty.</Alert>;
  }

  const currentQuestion = quiz.data[currentQuestionIndex];

  return (
    <Container>
      {!isFinished ? (
        <>
          <h3>Pytanie {currentQuestionIndex + 1} z {quiz.data.length}</h3>
          <p>{currentQuestion.question}</p>
          <div className="d-flex flex-wrap justify-content-start">
            {shuffledAnswers.map((answer, index) => (
              <Button
                key={index}
                variant={userAnswers[currentQuestionIndex] === answer ? "primary" : "outline-primary"}
                onClick={() => handleAnswer(answer)}
                className="mb-2 me-2"
              >
                {answer}
              </Button>
            ))}
          </div>
          <Button onClick={handleNext} className="mt-3">
            {currentQuestionIndex < quiz.data.length - 1 ? 'Następne' : 'Zakończ'}
          </Button>
        </>
      ) : (
        <>
          <Alert variant="info">
            Twój wynik: {score} / {quiz.data.length}
          </Alert>
          <h4>Podsumowanie odpowiedzi:</h4>
{quiz.data.map((question, index) => (
  <div key={index} className="mb-3">
    <p><strong>Pytanie {index + 1}:</strong> {question.question}</p>
    <div>
      {question.incorrectAnswers.concat(question.correctAnswer).map((answer, answerIndex) => (
        <Button
          key={answerIndex}
          variant={
            answer === question.correctAnswer ? 'primary' :
            (answer === userAnswers[index] && answer !== question.correctAnswer) ? 'danger' :
            'outline-secondary'
          }
          className="me-2 mb-2"
          style={{
            borderColor: answer === userAnswers[index] ? 'black' : 'grey',
            borderWidth: answer === userAnswers[index] ? '2px' : '1px'
          }}
        >
          {answer}
        </Button>
      ))}
    </div>
  </div>
))}
          <Button onClick={onClose}>Zamknij</Button>
        </>
      )}
    </Container>
  );
};

export default MultipleChoiceQuizGame;
