import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../services/api';
import LoadingSpinner from './LoadingSpinner';

const UserCourseDetails = () => {
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [courseResponse, lessonsResponse] = await Promise.all([
          api.get(`/courses/${id}`),
          api.get(`/courses/${id}/lessons`)
        ]);
        setCourse(courseResponse.data);
        setLessons(lessonsResponse.data);
        if (lessonsResponse.data.length > 0) {
          setSelectedLesson(lessonsResponse.data[0]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Nie udało się pobrać danych kursu. Spróbuj ponownie później.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleLessonSelect = (lesson) => {
    setSelectedLesson(lesson);
  };

  const handleViewFullLesson = () => {
    if (selectedLesson) {
      navigate(`/lessons/${selectedLesson.id}/view`);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!course) {
    return <div className="alert alert-warning">Nie znaleziono kursu.</div>;
  }

  return (
    <div className="bg-light container mt-4 p-4">
      <h2>{course.title}</h2>
      <div className="ql-editor bg-light p-3 rounded mb-4" dangerouslySetInnerHTML={{ __html: course.description }} />

      <div className="row">
        <div className="col-md-4">
          <h3>Lekcje</h3>
          <ul className="list-group">
            {lessons.map((lesson) => (
              <li
                key={lesson.id}
                className={`list-group-item ${selectedLesson && selectedLesson.id === lesson.id ? 'active' : ''}`}
                onClick={() => handleLessonSelect(lesson)}
                style={{cursor: 'pointer'}}
              >
                {lesson.title}
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-8">
          <h3>Zawartość lekcji</h3>
          {selectedLesson ? (
            <>
              <div className="ql-editor bg-light p-3 rounded mb-3" dangerouslySetInnerHTML={{ __html: selectedLesson.content }} />
              <button
                className="btn btn-primary"
                onClick={handleViewFullLesson}
              >
                Zobacz pełną lekcję
              </button>
            </>
          ) : (
            <p>Wybierz lekcję, aby zobaczyć jej zawartość.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCourseDetails;
