import React, { useState, useEffect } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import TranslationQuizGame from './TranslationQuizGame';
import CategoryQuizGame from './CategoryQuizGame';
import SentenceQuizGame from './SentenceQuizGame';
import MultipleChoiceQuizGame from './MultipleChoiceQuizGame';
import VideoQuizGame from './VideoQuizGame';



const API_URL = 'https://kursy.glon.org/quiz/api/';

const QuizPlayer = ({ quizId, show, onHide }) => {
  const [quiz, setQuiz] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchQuiz = async () => {
      if (!quizId) return;

      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}?id=${quizId}`);
        setQuiz(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching quiz:', error);
        setError('Nie udało się pobrać danych quizu.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuiz();
  }, [quizId]);

  const renderQuizContent = () => {
    if (isLoading) {
      return <Alert variant="info">Ładowanie quizu...</Alert>;
    }

    if (error) {
      return <Alert variant="danger">{error}</Alert>;
    }

    if (!quiz) {
      return <Alert variant="warning">Nie znaleziono quizu.</Alert>;
    }

    switch (quiz.type) {
      case 'translation':
        return <TranslationQuizGame quizId={quizId} onClose={onHide} />;
      case 'category':
        return <CategoryQuizGame quizId={quizId} onClose={onHide} />;
      case 'sentence':
        return <SentenceQuizGame quizId={quizId} onClose={onHide} />;
      case 'multipleChoice':
        return <MultipleChoiceQuizGame quizId={quizId} onClose={onHide} />;
      case 'video':
        return <VideoQuizGame quizId={quizId} onClose={onHide} />;
      default:
        return <Alert variant="warning">Nieobsługiwany typ quizu: {quiz.type}</Alert>;
    }
  };

/*size="lg" centered*/
  return (
    <Modal show={show} onHide={onHide} 
	  backdrop="static" keyboard={false} fullscreen={true} >
      <Modal.Header closeButton>
        <Modal.Title>{quiz ? quiz.name : 'Quiz'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderQuizContent()}
      </Modal.Body>
	  {/*<Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Zamknij
        </Button>
      </Modal.Footer>*/}
    </Modal>
  );
};

export default QuizPlayer;
