import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const SentenceQuizGame = ({ quiz, onClose }) => {
  const [sentence, setSentence] = useState({});
  const [availableWords, setAvailableWords] = useState([]);
  const [filledGaps, setFilledGaps] = useState({});
  const [isFinished, setIsFinished] = useState(false);
  const [score, setScore] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!quiz || !quiz.data || quiz.data.length === 0) {
      setError("Nieprawidłowe dane quizu");
      return;
    }

    const currentSentence = quiz.data[0]; // Assuming we're working with a single sentence
    setSentence(currentSentence);

    const words = [
      ...currentSentence.correctWords,
      ...currentSentence.incorrectWords
    ].map((word, index) => ({ id: `word-${index}`, content: word }));

    setAvailableWords(shuffleArray(words));
    setFilledGaps({});
  }, [quiz]);

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === 'availableWords' && destination.droppableId.startsWith('gap-')) {
      const gapNumber = destination.droppableId.split('-')[1];
      const wordIndex = source.index;
      const word = availableWords[wordIndex];

      // If there's already a word in the gap, swap it
      if (filledGaps[gapNumber]) {
        setAvailableWords(prev => [...prev, filledGaps[gapNumber]]);
      }

      setFilledGaps(prev => ({ ...prev, [gapNumber]: word }));
      setAvailableWords(prev => prev.filter((_, index) => index !== wordIndex));
    } else if (source.droppableId.startsWith('gap-') && destination.droppableId === 'availableWords') {
      const gapNumber = source.droppableId.split('-')[1];
      const word = filledGaps[gapNumber];

      setAvailableWords(prev => [...prev, word]);
      setFilledGaps(prev => {
        const newFilledGaps = { ...prev };
        delete newFilledGaps[gapNumber];
        return newFilledGaps;
      });
    }
  };

  const renderSentence = () => {
    if (!sentence.text) return null;

    const parts = sentence.text.split(/(@\d+)/);
    return parts.map((part, index) => {
      if (part.match(/@\d+/)) {
        const gapNumber = part.substring(1);
        return (
          <Droppable key={part} droppableId={`gap-${gapNumber}`}>
            {(provided, snapshot) => (
              <span
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  padding: '0.2em 0.5em',
                  margin: '0 0.2em',
                  minWidth: '80px',
                  display: 'inline-block',
                  border: '1px solid gray',
                  backgroundColor: getGapBackgroundColor(gapNumber, snapshot.isDraggingOver),
                }}
              >
                {filledGaps[gapNumber] ? (
                  <Draggable draggableId={filledGaps[gapNumber].id} index={0}>
                    {(provided, snapshot) => (
                      <span
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          padding: '0.2em',
                          ...provided.draggableProps.style
                        }}
                      >
                        {filledGaps[gapNumber].content}
                      </span>
                    )}
                  </Draggable>
                ) : part}
                {provided.placeholder}
              </span>
            )}
          </Droppable>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  const getGapBackgroundColor = (gapNumber, isDraggingOver) => {
    if (isFinished) {
      const isCorrect = sentence.correctWords[parseInt(gapNumber) - 1] === filledGaps[gapNumber]?.content;
      return isCorrect ? 'lightblue' : 'lightcoral';
    }
    return isDraggingOver ? 'lightgreen' : 'white';
  };

  const checkAnswers = () => {
    let correctCount = 0;
    sentence.correctWords.forEach((word, index) => {
      const gapNumber = (index + 1).toString();
      if (filledGaps[gapNumber] && filledGaps[gapNumber].content === word) {
        correctCount++;
      }
    });
    setScore(correctCount);
    setIsFinished(true);
  };

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="h5 mb-4">
          {renderSentence()}
        </div>
        <Droppable droppableId="availableWords" direction="horizontal">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ display: 'flex', flexWrap: 'wrap', minHeight: '50px', padding: '10px', backgroundColor: '#f0f0f0' }}
            >
              {availableWords.map((word, index) => (
                <Draggable key={word.id} draggableId={word.id} index={index}>
                  {(provided, snapshot) => (
                    <span
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: 'none',
                        padding: '0.5em',
                        margin: '0 0.5em 0.5em 0',
                        backgroundColor: snapshot.isDragging ? 'lightgreen' : 'white',
                        border: '1px solid gray',
                        ...provided.draggableProps.style
                      }}
                    >
                      {word.content}
                    </span>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {!isFinished ? (
          <Button onClick={checkAnswers} className="mx-2 mt-3">Sprawdź odpowiedzi</Button>
        ) : (
          <>
            <Alert variant="info" className="mt-3">
              Twój wynik: {score} / {sentence.correctWords.length}
            </Alert>
          </>
        )}
            <Button  className="mt-3 mx-2" onClick={onClose}>Zamknij</Button>
      </DragDropContext>
    </Container>
  );
};

export default SentenceQuizGame;
