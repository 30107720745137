import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const TranslationQuizGame = ({ quiz, onClose }) => {
  const [words1, setWords1] = useState([]);
  const [words2, setWords2] = useState([]);
  const [matches, setMatches] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (quiz) {
      const shuffledWords1 = shuffleArray(quiz.data.map(pair => ({ id: pair.word1, content: pair.word1 })));
      const shuffledWords2 = shuffleArray(quiz.data.map(pair => ({ id: pair.word2, content: pair.word2 })));
      setWords1(shuffledWords1);
      setWords2(shuffledWords2);
      setMatches([]);
      setChecked(false);
    }
  }, [quiz]);

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        source.droppableId === 'words1' ? words1 : words2,
        source.index,
        destination.index
      );

      if (source.droppableId === 'words1') {
        setWords1(items);
      } else {
        setWords2(items);
      }
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const checkAnswers = () => {
    const newMatches = words1.map((word, index) => ({
      word1: word.content,
      word2: words2[index].content,
      correct: quiz.data.some(pair => pair.word1 === word.content && pair.word2 === words2[index].content)
    }));
    setMatches(newMatches);
    setChecked(true);
  };

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Row>
          <Col>
            <Droppable droppableId="words1">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {words1.map((word, index) => (
                    <Draggable key={word.id} draggableId={word.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`p-2 mb-2 border ${
                            checked
                              ? matches[index]?.correct
                                ? 'bg-primary text-white'
                                : 'bg-danger text-white'
                              : snapshot.isDragging
                                ? 'bg-secondary text-white'
                                : 'bg-light'
                          }`}
                        >
                          {word.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Col>
          <Col>
            <Droppable droppableId="words2">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {words2.map((word, index) => (
                    <Draggable key={word.id} draggableId={word.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`p-2 mb-2 border ${
                            checked
                              ? matches[index]?.correct
                                ? 'bg-primary text-white'
                                : 'bg-danger text-white'
                              : snapshot.isDragging
                                ? 'bg-secondary text-white'
                                : 'bg-light text-dark'
                          }`}
                        >
                          {word.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Col>
        </Row>
      </DragDropContext>
      <Button onClick={checkAnswers} disabled={checked} className="mx-2 mt-3 mr-2">
        Sprawdź
      </Button>
      <Button onClick={onClose} className="mt-3 mx-2">
        Zakończ
      </Button>
    </Container>
  );
};

export default TranslationQuizGame;
