import React from 'react';
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <div className="container mt-4">
	  <div className="row">
	  <div className="col-md-6">
      <h2>Kontakt</h2>
      <p>Jeśli masz pytania lub sugestie, skontaktuj się z nami:</p>
      <ul>
        <li>Email: kontakt@example.com</li>
        <li>Telefon: +48 123 456 789</li>
        <li>Adres: ul. Przykładowa 1, 00-000 Warszawa</li>
      </ul>
	  </div>
	  <div className="col-md-6">
      <form>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Twój email:</label>
          <input type="email" className="form-control" id="email" required />
        </div>
        <div className="mb-3">
          <label htmlFor="message" className="form-label">Wiadomość:</label>
          <textarea className="form-control" id="message" rows="5" required></textarea>
        </div>
        <button type="submit" className="btn btn-primary">Wyślij</button>
      </form>
	  </div>
	  </div>
	  <div>
	      <Link className="nav-link" to="/privacy-policy" ><h2>Polityka prywatności</h2></Link>
	  </div>
    </div>
  );
};

export default Contact;
