// components/Login.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Form, Button } from 'react-bootstrap';

// Define constants for registration and password change links
const REGISTER_LINK = 'https://kursy.glon.org/register/register.html';
const FORGOT_PASSWORD_LINK = 'https://kursy.glon.org/register/reset-password.html';

const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await login(credentials)) {
      navigate('/');
    } else {
      alert('Login failed. Please try again.');
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <Form onSubmit={handleSubmit} className="login-form p-4 shadow border" style={{ width: '500px' }}>
          <h2 className="text-center mb-4">Login</h2>
          <Form.Group controlId="formEmail" className="m-2">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder=""
              name="email"
              value={credentials.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formPassword" className="m-2">
            <Form.Label>Hasło</Form.Label>
            <Form.Control
              type="password"
              placeholder=""
              name="password"
              value={credentials.password}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button variant="primary m-2" type="submit" className="btn-block">
            Login
          </Button>
        </Form>
      </div>
      <div className="text-center mt-3">
	  {/*<Link to={REGISTER_LINK} className="mr-3">Rejestracja</Link>*/}
	          <a href={REGISTER_LINK} className="mr-3" rel="noopener noreferrer">Rejestracja</a>
        <span className="mx-2">|</span>
        <a href={FORGOT_PASSWORD_LINK} className="mr-3" rel="noopener noreferrer">Zapomniane hasło?</a>
      </div>
    </div>
  );
};

export default Login;
