import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

const TranslationQuizCreator = ({ initialQuiz, onSave, onCancel, isNameUnique }) => {
  const [quizId, setQuizId] = useState(null);
  const [quizName, setQuizName] = useState('');
  const [words1, setWords1] = useState('');
  const [words2, setWords2] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (initialQuiz) {
      setQuizId(initialQuiz.id || null);
      setQuizName(initialQuiz.name || '');
      setWords1(initialQuiz.data ? initialQuiz.data.map(pair => pair.word1).join('\n') : '');
      setWords2(initialQuiz.data ? initialQuiz.data.map(pair => pair.word2).join('\n') : '');
    }
  }, [initialQuiz]);

  const handleSave = () => {
    setError('');

    if (!quizName.trim()) {
      setError("Nazwa quizu jest wymagana.");
      return;
    }

    if (!isNameUnique(quizName, quizId)) {
      setError("Jest już taka nazwa. Wybierz inną.");
      return;
    }

    const words1Array = words1.split('\n').filter(word => word.trim() !== '');
    const words2Array = words2.split('\n').filter(word => word.trim() !== '');

    if (words1Array.length !== words2Array.length) {
      setError("Liczba słów w obu kolumnach musi być taka sama.");
      return;
    }

    if (words1Array.length === 0) {
      setError("Test musi zawierać co najmniej jedną parę słów.");
      return;
    }

    const quizData = {
      id: quizId,
      type: "translation",
      name: quizName,
      data: words1Array.map((word, index) => ({
        word1: word.trim(),
        word2: words2Array[index].trim()
      }))
    };

    onSave(quizData);
  };

  return (
    <Container className="bg-secondary-subtle p-4">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        <Form.Group>
          <Form.Label>Nazwa quizu</Form.Label>
          <Form.Control
            type="text"
            value={quizName}
            onChange={(e) => setQuizName(e.target.value)}
            placeholder="Wpisz nazwę quizu"
            required
          />
        </Form.Group>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Słowa w pierwszym języku</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                value={words1}
                onChange={(e) => setWords1(e.target.value)}
                placeholder="Wpisz słowa, każde w nowej linii"
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Słowa w drugim języku</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                value={words2}
                onChange={(e) => setWords2(e.target.value)}
                placeholder="Wpisz słowa, każde w nowej linii"
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Button className="mx-2 my-2" variant="primary" onClick={handleSave}>
          Zapisz Quiz
        </Button>
        <Button variant="secondary" onClick={onCancel} className="mx-2 ml-2 my-2">
          Anuluj
        </Button>
      </Form>
    </Container>
  );
};

export default TranslationQuizCreator;
