import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from '../services/api';

const ImageSelector = ({ show, onHide, onSelect }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (show) {
      fetchImages();
    }
  }, [show]);

  const fetchImages = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get('/images');
      if (response.data.success && typeof response.data.images === 'object') {
        const imagesArray = Object.values(response.data.images);
        setImages(imagesArray);
      } else {
        console.error('Unexpected API response format:', response.data);
        setImages([]);
        setError('Error: Unexpected data format received from server.');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
      setError('Failed to fetch images. Please try again.');
      setImages([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  const handleConfirm = () => {
    if (selectedImage) {
      onSelect(selectedImage);
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={onHide} fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>Wybierz obraz</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {error && <div className="alert alert-danger m-3">{error}</div>}
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Ładowanie...</span>
            </div>
          </div>
        ) : (
          <div className="row g-0">
            {images.map((image, index) => (
              <div key={index} className="col-6 col-md-3 col-lg-2 p-1">
                <img
                  src={`/img/${image}`}
                  alt={image}
                  className={`img-fluid ${selectedImage === image ? 'border border-primary border-4' : ''}`}
                  onClick={() => handleImageSelect(image)}
                  style={{ cursor: 'pointer', objectFit: 'cover', height: '150px', width: '100%' }}
                />
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Zamknij
        </Button>
        <Button variant="primary" onClick={handleConfirm} disabled={!selectedImage || isLoading}>
          Wybierz grafikę
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageSelector;
