import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import api from '../services/api';

const LessonSelector = ({ show, onHide, courseId }) => {
  const [lessons, setLessons] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (show) {
      fetchLessons();
    }
  }, [show, courseId]);

  const fetchLessons = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get(`/courses/${courseId}/unassigned-lessons`);
      setLessons(response.data);
    } catch (error) {
      console.error('Error fetching lessons:', error);
      setError('Failed to fetch lessons. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddLesson = async (lesson) => {
    try {
      await api.post(`/courses/${courseId}/lessons`, {
        lesson_id: lesson.id,
        lesson_order: lessons.length + 1
      });
      // Remove the added lesson from the list
      setLessons(prevLessons => prevLessons.filter(l => l.id !== lesson.id));
    } catch (error) {
      console.error('Error adding lesson to course:', error);
      setError('Failed to add lesson to course. Please try again.');
    }
  };

  return (
    <Modal show={show} onHide={onHide} fullscreen={false} size="xl" scrollable={true}>
      <Modal.Header closeButton>
        <Modal.Title>Dodaj lekcję do kursu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Ładowanie...</span>
            </div>
          </div>
        ) : (
          <div className="list-group">
            {lessons.map((lesson) => (
              <div key={lesson.id} className="list-group-item d-flex justify-content-between align-items-center">
                <div>{lesson.title}</div>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleAddLesson(lesson)}
                >
                  Dodaj
                </Button>
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Zamknij
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LessonSelector;
