import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from '../services/api';
import { quizapi } from '../services/api';
import LoadingSpinner from './LoadingSpinner';
import ImageSelector from './ImageSelector';
import QuizListModal from './QuizListModal';
import QuizPlayer from './QuizPlayer';

const LessonDetails = ({user}) => {
  const [lesson, setLesson] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [showImageSelector, setShowImageSelector] = useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [lessonContent, setLessonContent] = useState([]);
  const [playingQuizId, setPlayingQuizId] = useState(null);
  const { id } = useParams();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      //const [lessonResponse, userResponse, contentResponse] = await Promise.all([
      const [lessonResponse, contentResponse] = await Promise.all([
        api.get(`/lessons/${id}`),
        //api.get('/user'),
        api.get(`/lessons/${id}/content`)
      ]);
      setLesson(lessonResponse.data);
      //setUserRole(userResponse.data.role);
    setUserRole(user.role);

      // Pobierz szczegóły quizów
      const contentWithDetails = await Promise.all(
        contentResponse.data.map(async (content) => {
          const quizDetails = await quizapi.get(`/?id=${content.content_id}`);
          return {
            ...content,
            quizName: quizDetails.data.name,
            quizType: quizDetails.data.type
          };
        })
      );
      setLessonContent(contentWithDetails);
      setError(null);
    } catch (error) {
      setError('Failed to fetch data. Please try again later.');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id,user]);

  const handleImageChange = async (selectedImage) => {
    try {
      await api.put(`/lessons/${id}`, { image_url: `/img/${selectedImage}` });
      await fetchData(); // Ponowne pobranie danych lekcji po zmianie obrazka
      setShowImageSelector(false);
    } catch (error) {
      console.error('Error updating lesson image:', error);
      setError('Failed to update lesson image. Please try again.');
    }
  };

  const handleQuizSelect = async (selectedQuiz) => {
    try {
      const isQuizAlreadyAssigned = lessonContent.some(content => content.content_id === selectedQuiz.id);

      if (isQuizAlreadyAssigned) {
        setMessage('Ten quiz jest już przypisany do lekcji.');
        return;
      }

      const newContent = {
        content_id: selectedQuiz.id
      };
      const response = await api.post(`/lessons/${id}/content`, newContent);
      const quizDetails = await quizapi.get(`/?id=${selectedQuiz.id}`);
      const newContentWithDetails = {
        ...response.data,
        quizName: quizDetails.data.name,
        quizType: quizDetails.data.type
      };
      setLessonContent([...lessonContent, newContentWithDetails]);
      setShowQuizModal(false);
      setMessage(null);
    } catch (error) {
      console.error('Error assigning quiz to lesson:', error);
      setError('Nie udało się przypisać quizu do lekcji. Proszę spróbować ponownie.');
    }
  };

  const handleRemoveContent = async (contentId) => {
    try {
      await api.delete(`/lessons/${id}/content/${contentId}`);
      setLessonContent(lessonContent.filter(content => content.id !== contentId));
    } catch (error) {
      console.error('Error removing content from lesson:', error);
      setError('Failed to remove content from lesson. Please try again.');
    }
  };

  const handleClearMessage = () => {
    setMessage(null);
  };

  const handleStartQuiz = (quizId) => {
    setPlayingQuizId(quizId);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!lesson) {
    return <div className="alert alert-warning">Lesson not found.</div>;
  }

  return (
    <div className="container-fluid mt-4">
      <h2 className="mb-4">{lesson.title}</h2>
      <div className="row border p-4">
        <div className="col-md-3">
          {userRole === 'teacher' && (
            <button className="btn btn-primary btn-sm mb-3 w-100" onClick={() => setShowImageSelector(true)}>
              Zmień grafikę
            </button>
          )}
          {lesson.image_url && (
            <img src={lesson.image_url} className="img-fluid mb-3" alt={lesson.title} />
          )}
        </div>
        <div className="col-md-6">
          {userRole === 'teacher' && (
            <div className="mb-3">
              <Link to={`/lessons/${id}/edit`} className="btn btn-secondary w-100">Edytuj lekcję</Link>
            </div>
          )}
          <div
            className="ql-editor bg-light p-3 rounded mb-3"
            dangerouslySetInnerHTML={{ __html: lesson.content }}
          />
        </div>
        <div className="col-md-3">
          {userRole === 'teacher' && (
            <button className="btn btn-success btn-sm mb-3 w-100" onClick={() => setShowQuizModal(true)}>
              Dodaj test
            </button>
          )}
          <h4>Przypisane quizy:</h4>
          <ul className="list-group">
            {lessonContent.map(content => (
              <li key={content.id} className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                  <strong>{content.quizName}</strong>
                  <br />
                  <small className="text-muted">{content.quizType}</small>
                </div>
                <div>
                  <button className="btn btn-primary btn-sm me-2" onClick={() => handleStartQuiz(content.content_id)}>
                    Uruchom
                  </button>
                  {userRole === 'teacher' && (
                    <button className="btn btn-danger btn-sm" onClick={() => handleRemoveContent(content.id)}>
                      Usuń
                    </button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {userRole === 'teacher' && (
        <>
          <ImageSelector
            show={showImageSelector}
            onHide={() => setShowImageSelector(false)}
            onSelect={handleImageChange}
          />
          <QuizListModal
            show={showQuizModal}
            onHide={() => setShowQuizModal(false)}
            onSelectQuiz={handleQuizSelect}
            message={message}
            onClearMessage={handleClearMessage}
          />
        </>
      )}

      <QuizPlayer
        quizId={playingQuizId}
        show={!!playingQuizId}
        onHide={() => setPlayingQuizId(null)}
      />
    </div>
  );
};

export default LessonDetails;
