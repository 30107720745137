import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../services/api';
import { quizapi } from '../services/api';
import LoadingSpinner from './LoadingSpinner';
import QuizPlayer from './QuizPlayer';

const LessonView = () => {
  const [lesson, setLesson] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lessonContent, setLessonContent] = useState([]);
  const [playingQuizId, setPlayingQuizId] = useState(null);
  const { id } = useParams();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [lessonResponse, contentResponse] = await Promise.all([
        api.get(`/lessons/${id}`),
        api.get(`/lessons/${id}/content`)
      ]);
      setLesson(lessonResponse.data);

      // Fetch quiz details
      const contentWithDetails = await Promise.all(
        contentResponse.data.map(async (content) => {
          const quizDetails = await quizapi.get(`/?id=${content.content_id}`);
          return {
            ...content,
            quizName: quizDetails.data.name,
            quizType: quizDetails.data.type
          };
        })
      );
      setLessonContent(contentWithDetails);
      setError(null);
    } catch (error) {
      setError('Failed to fetch lesson data. Please try again later.');
      console.error('Error fetching lesson data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleStartQuiz = (quizId) => {
    setPlayingQuizId(quizId);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!lesson) {
    return <div className="alert alert-warning">Lesson not found.</div>;
  }

  return (
    <div className="container-fluid mt-4 bg-light">
      <h2 className="mb-4">{lesson.title}</h2>
      <div className="row border p-4">
        <div className="col-md-4">
          {lesson.image_url && (
            <img src={lesson.image_url} className="img-fluid my-3" alt={lesson.title} />
          )}
        </div>
        <div className="col-md-8">
          <div
            className="ql-editor bg-light p-3 rounded mb-3"
            dangerouslySetInnerHTML={{ __html: lesson.content }}
          />
          <h4></h4>
<ul className="list-group">
  {lessonContent.map(content => (
    <li key={content.id} className="list-group-item d-flex justify-content-between align-items-center">
      <div>
        <strong>{content.quizName}</strong>
        <br />
        <small className="text-muted">{content.quizType}</small>
      </div>
      <button
        className="btn btn-primary btn-sm"
        onClick={() => handleStartQuiz(content.content_id)}
      >
        {content.quizType.toLowerCase() === 'video' ? 'Play' : 'Start'}
      </button>
    </li>
  ))}
</ul>
        </div>
      </div>

      <QuizPlayer
        quizId={playingQuizId}
        show={!!playingQuizId}
        onHide={() => setPlayingQuizId(null)}
      />
    </div>
  );
};

export default LessonView;
