import React, { useState } from 'react';

const Help = () => {
  const [activeKey, setActiveKey] = useState('0');

  const toggleAccordion = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const faqItems = [
    {
      question: 'Jak mogę się zarejestrować?',
      answer: 'Aby się zarejestrować, kliknij przycisk "Zarejestruj się" w prawym górnym rogu strony i wypełnij formularz rejestracyjny.'
    },
    {
      question: 'Jak mogę zapisać się na kurs?',
      answer: 'Po zalogowaniu, przejdź do sekcji "Kursy", wybierz interesujący Cię kurs i kliknij przycisk "Zapisz się".'
    },
    {
      question: 'Jak mogę zresetować moje hasło?',
      answer: 'Na stronie logowania kliknij link "Zapomniałeś hasła?", a następnie podaj swój adres email. Wyślemy Ci instrukcje resetowania hasła.'
    }
  ];

  return (
    <div className="container mt-4">
      <h2>Pomoc</h2>
      <h3>Często zadawane pytania:</h3>
      <div className="accordion">
        {faqItems.map((item, index) => (
          <div className="accordion-item" key={index}>
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeKey !== index.toString() ? 'collapsed' : ''}`}
                type="button"
                onClick={() => toggleAccordion(index.toString())}
              >
                {item.question}
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${activeKey === index.toString() ? 'show' : ''}`}
            >
              <div className="accordion-body">
                {item.answer}
              </div>
            </div>
          </div>
        ))}
      </div>
      <p className="mt-4">Jeśli nie znalazłeś odpowiedzi na swoje pytanie, skontaktuj się z nami przez formularz kontaktowy.</p>
    </div>
  );
};

export default Help;
