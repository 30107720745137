import React, { useState, useEffect } from 'react';
import { Container, Button, Alert, Form } from 'react-bootstrap';
import axios from 'axios';

const API_URL = 'https://kursy.glon.org/quiz/api/';

const MultipleChoiceQuizGame = ({ quizId, onClose }) => {
  const [quiz, setQuiz] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [shuffledAnswersOrder, setShuffledAnswersOrder] = useState([]);
  const [isFinished, setIsFinished] = useState(false);
  const [score, setScore] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchQuiz = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}?id=${quizId}`);
        setQuiz(response.data);
        initializeQuiz(response.data);
      } catch (error) {
        console.error('Error fetching quiz:', error);
        setError('Nie udało się pobrać danych quizu.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuiz();
  }, [quizId]);

  const initializeQuiz = (quizData) => {
    if (!quizData || !quizData.data || quizData.data.length === 0) {
      setError("Nieprawidłowe dane quizu");
      return;
    }
    setUserAnswers(new Array(quizData.data.length).fill(null));
    const initialShuffledAnswers = quizData.data.map(question =>
      shuffleArray([question.correctAnswer, ...question.incorrectAnswers])
    );
    setShuffledAnswersOrder(initialShuffledAnswers);
  };

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleAnswer = (answer) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestionIndex] = answer;
    setUserAnswers(newUserAnswers);
  };

  const handleNext = () => {
    if (currentQuestionIndex < quiz.data.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    } else {
      finishQuiz();
    }
  };

  const finishQuiz = () => {
    let correctCount = 0;
    quiz.data.forEach((question, index) => {
      if (userAnswers[index] === question.correctAnswer) {
        correctCount++;
      }
    });
    setScore(correctCount);
    setIsFinished(true);
  };

  const renderQuizSummary = () => {
    return quiz.data.map((question, index) => (
      <div key={index} className="mb-4">
        <h4>Pytanie {index + 1}</h4>
        <p>{question.question}</p>
        <div className="d-flex flex-wrap">
          {shuffledAnswersOrder[index].map((answer, answerIndex) => (
            <Button
              key={answerIndex}
              variant={
                answer === question.correctAnswer
                  ? 'primary'
                  : userAnswers[index] === answer
                  ? 'danger'
                  : 'outline-secondary'
              }
              className="m-2"
              disabled
            >
              {answer}
            </Button>
          ))}
        </div>
      </div>
    ));
  };

  if (isLoading) {
    return <Alert variant="info">Ładowanie quizu...</Alert>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!quiz || !quiz.data || currentQuestionIndex >= quiz.data.length) {
    return <Alert variant="warning">Brak danych quizu lub quiz jest pusty.</Alert>;
  }

  const currentQuestion = quiz.data[currentQuestionIndex];

  return (
    <Container>
      {!isFinished ? (
        <>
          <h3>Pytanie {currentQuestionIndex + 1} z {quiz.data.length}</h3>
          <p>{currentQuestion.question}</p>
          <div className="d-flex flex-wrap">
            {shuffledAnswersOrder[currentQuestionIndex].map((answer, index) => (
              <Button
                key={index}
                variant={userAnswers[currentQuestionIndex] === answer ? 'primary' : 'outline-primary'}
                className="m-2"
                onClick={() => handleAnswer(answer)}
              >
                {answer}
              </Button>
            ))}
          </div>
          <Button onClick={handleNext} className="mt-3">
            {currentQuestionIndex < quiz.data.length - 1 ? 'Następne' : 'Zakończ'}
          </Button>
        </>
      ) : (
        <>
          <Alert variant="info">
            Twój wynik: {score} / {quiz.data.length}
          </Alert>
          <h3>Podsumowanie quizu</h3>
          {renderQuizSummary()}
          <Button onClick={onClose} className="mt-3">Zamknij</Button>
        </>
      )}
    </Container>
  );
};

export default MultipleChoiceQuizGame;
