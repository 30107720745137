import React, { useState, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import api from '../services/api';
import VideoList from './VideoList';

const VideoManager = () => {
  const [message, setMessage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});
  const videoListRef = useRef();

  const sanitizeFileName = (fileName) => {
    return fileName.replace(/\s+/g, '_');
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const sanitizedFileName = sanitizeFileName(file.name);
      const formData = new FormData();
      formData.append('video', file, sanitizedFileName);

      setUploadProgress(prev => ({ ...prev, [sanitizedFileName]: 0 }));

      api.post('/videos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(prev => ({ ...prev, [sanitizedFileName]: percentCompleted }));
        },
      })
      .then(response => {
        setMessage(`${sanitizedFileName} uploaded successfully.`);
        if (videoListRef.current) {
          videoListRef.current.refreshList();
        }
      })
      .catch(error => {
        console.error('Error uploading video:', error);
        setMessage(`Failed to upload ${sanitizedFileName}. Please try again.`);
      })
      .finally(() => {
        setTimeout(() => {
          setUploadProgress(prev => {
            const newProgress = { ...prev };
            delete newProgress[sanitizedFileName];
            return newProgress;
          });
        }, 1000);
      });
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: 'video/*',
    noClick: true,
    noKeyboard: true
  });

  return (
    <div className="container mt-4">
      <h2>Video Manager</h2>
      {message && (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          {message}
          <button type="button" className="btn-close" onClick={() => setMessage(null)}></button>
        </div>
      )}
      <div {...getRootProps()} className="dropzone mb-3 p-4 border-dark border rounded bg-secondary-subtle text-center">
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the video files here ...</p> :
            <p>Przeciągnij tutaj film albo <button onClick={open} className="btn btn-outline-dark btn-sm">wybierz</button></p>
        }
      </div>
      {Object.entries(uploadProgress).map(([fileName, progress]) => (
        <div key={fileName} className="mb-2">
          <strong>{fileName}</strong>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{width: `${progress}%`}}
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {progress}%
            </div>
          </div>
        </div>
      ))}
      <VideoList ref={videoListRef} />
    </div>
  );
};

export default VideoManager;
