import React from 'react';

const UserOrders = ({ orders }) => {
  const formatDate = (utcDateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit',
      timeZone: 'Europe/Warsaw',
      hour12: false
    };
    const date = new Date(utcDateString + 'Z');
    return new Intl.DateTimeFormat('pl-PL', options).format(date);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      case 'rejected':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  const translateStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'Oczekujące';
      case 'approved':
        return 'Zatwierdzone';
      case 'rejected':
        return 'Odrzucone';
      default:
        return status;
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Zamówienia</h3>
        {orders.length === 0 ? (
          <p>Brak zamówień.</p>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>ID Zamówienia</th>
                <th>Kurs</th>
                <th>Status</th>
                <th>Data utworzenia</th>
              </tr>
            </thead>
            <tbody>
              {orders.map(order => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>{order.course_title}</td>
                  <td>
                    <span className={`badge bg-${getStatusColor(order.status)}`}>
                      {translateStatus(order.status)}
                    </span>
                  </td>
                  <td>{formatDate(order.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UserOrders;
