import React, { useState, useEffect } from 'react';
import api from '../services/api';
import LoadingSpinner from './LoadingSpinner';

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  const [expandedUsers, setExpandedUsers] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      setIsLoading(true);
      const response = await api.get('/orders/all');
      const groupedOrders = groupOrdersByUser(response.data);
      setOrders(groupedOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError('Nie udało się pobrać zamówień. Spróbuj ponownie później.');
    } finally {
      setIsLoading(false);
    }
  };

  const groupOrdersByUser = (orders) => {
    return orders.reduce((acc, order) => {
      if (!acc[order.user_id]) {
        acc[order.user_id] = {
          user_name: order.user_name,
          orders: []
        };
      }
      acc[order.user_id].orders.push(order);
      return acc;
    }, {});
  };

  const handleOrderAction = async (orderId, newStatus) => {
    try {
      await api.put(`/orders/${orderId}`, { status: newStatus });
      fetchOrders();
    } catch (error) {
      console.error(`Error updating order status:`, error);
      setError(`Nie udało się zaktualizować statusu zamówienia. Spróbuj ponownie.`);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Europe/Warsaw',
      hour12: false
    };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('pl-PL', options).format(date);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending': return 'warning';
      case 'approved': return 'success';
      case 'rejected': return 'danger';
      default: return 'secondary';
    }
  };

  const translateStatus = (status) => {
    switch (status) {
      case 'pending': return 'Oczekujące';
      case 'approved': return 'Zatwierdzone';
      case 'rejected': return 'Odrzucone';
      default: return status;
    }
  };

  const toggleUserExpansion = (userId) => {
    setExpandedUsers(prev =>
      prev.includes(userId) ? prev.filter(id => id !== userId) : [...prev, userId]
    );
  };

  const filteredOrders = Object.entries(orders).reduce((acc, [userId, userData]) => {
    const filteredUserOrders = userData.orders.filter(order =>
      statusFilter === 'all' || order.status === statusFilter
    );
    if (filteredUserOrders.length > 0) {
      acc[userId] = { ...userData, orders: filteredUserOrders };
    }
    return acc;
  }, {});

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="container mt-4">
      <h2>Zarządzanie Zamówieniami</h2>
      <div className="mb-3">
        <button className="btn btn-primary me-2" onClick={fetchOrders}>Odśwież listę</button>
        <label htmlFor="statusFilter" className="form-label me-2">Filtruj według statusu:</label>
        <select
          id="statusFilter"
          className="form-select d-inline-block w-auto"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="all">Wszystkie</option>
          <option value="pending">Oczekujące</option>
          <option value="approved">Zatwierdzone</option>
          <option value="rejected">Odrzucone</option>
        </select>
      </div>
      {Object.keys(filteredOrders).length === 0 ? (
        <p>Brak zamówień do wyświetlenia.</p>
      ) : (
        Object.entries(filteredOrders).map(([userId, userData]) => (
          <div key={userId} className="card mb-3">
            <div className="card-header" onClick={() => toggleUserExpansion(userId)} style={{cursor: 'pointer'}}>
              <h5>{userData.user_name} ({userData.orders.length} zamówień)</h5>
            </div>
            {expandedUsers.includes(userId) && (
              <div className="card-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>ID Zamówienia</th>
                      <th>Kurs</th>
                      <th>Data utworzenia</th>
                      <th>Status</th>
                      <th>Akcje</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData.orders.map(order => (
                      <tr key={order.id}>
                        <td>{order.id}</td>
                        <td>{order.course_title}</td>
                        <td>{formatDate(order.created_at)}</td>
                        <td>
                          <span className={`badge bg-${getStatusColor(order.status)}`}>
                            {translateStatus(order.status)}
                          </span>
                        </td>
                        <td>
                          {order.status === 'pending' && (
                            <>
                              <button
                                className="btn btn-success btn-sm me-2"
                                onClick={() => handleOrderAction(order.id, 'approved')}
                              >
                                Zatwierdź
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleOrderAction(order.id, 'rejected')}
                              >
                                Odrzuć
                              </button>
                            </>
                          )}
                          {(order.status === 'approved' || order.status === 'rejected') && (
                            <button
                              className="btn btn-warning btn-sm"
                              onClick={() => handleOrderAction(order.id, 'pending')}
                            >
                              Przywróć do oczekujących
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default OrderManagement;
