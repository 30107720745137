import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import QuizManager from '../quiz/QuizManager';
import { Container } from 'react-bootstrap';

function Content() {
  return (
     <Container fluid>
      <QuizManager />
     </Container>
  );
}

export default Content;
