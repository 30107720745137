import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import api from '../services/api';
import VideoPlayer from './VideoPlayer';

const VideoList = forwardRef((props, ref) => {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchVideos = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get('/videos');
      if (response.data.success && Array.isArray(response.data.videos)) {
        setVideos(response.data.videos);
      } else {
        throw new Error('Unexpected data format received from server.');
      }
    } catch (error) {
      console.error('Error fetching videos:', error);
      setError('Failed to fetch videos. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    refreshList: fetchVideos
  }));

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  const handleDelete = async (fileName) => {
    try {
      const response = await api.delete(`/videos/${fileName}`);
      if (response.data.success) {
        fetchVideos(); // Reload the list after successful deletion
      } else {
        throw new Error(response.data.message || 'Failed to delete video.');
      }
    } catch (error) {
      console.error('Error deleting video:', error);
      setError(`Failed to delete ${fileName}. Please try again.`);
    }
  };

  if (isLoading) {
    return <div>Loading videos...</div>;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div>
      <h3>Filmy</h3>
	  {/*<button onClick={fetchVideos} className="btn btn-primary mb-3">
        Reload Videos
      </button>*/}
      <div className="row">
        {videos.map((video, index) => (
          <div key={index} className="col-md-6 mb-3">
            <div className="card">
              <div className="card-body">
                <VideoPlayer src={video} title={video} />
                <button
                  onClick={() => handleDelete(video)}
                  className="btn btn-sm btn-danger mt-2"
                >
                  Usuń
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default VideoList;
