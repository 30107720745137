import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from '../services/api';
import LoadingSpinner from './LoadingSpinner';
import ImageSelector from './ImageSelector';
import LessonSelector from './LessonSelector';
// import ParallaxComponent from './ParallaxComponent';

const CourseDetails = ({user}) => {
  const [course, setCourse] = useState(null);
  const [courseLessons, setCourseLessons] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showImageSelector, setShowImageSelector] = useState(false);
  const [showLessonSelector, setShowLessonSelector] = useState(false);
  const [enrollmentMessage, setEnrollmentMessage] = useState(null);
  const { id } = useParams();

    const fetchData = async () => {
      try {
        //const [courseResponse, userResponse, lessonsResponse] = await Promise.all([
        const [courseResponse, lessonsResponse] = await Promise.all([
          api.get(`/courses/${id}`),
          //api.get('/user'),
          api.get(`/courses/${id}/lessons`)
        ]);
        setCourse(courseResponse.data);
        //setUserRole(userResponse.data.role);
        setCourseLessons(lessonsResponse.data);
      } catch (error) {
        setError('Failed to fetch data. Please try again later.');
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };


  useEffect(() => {
    fetchData();
    setUserRole(user.role);
  }, [id,user]);

  const handleImageChange = async (selectedImage) => {
    try {
      await api.put(`/courses/${id}`, { image_url: `/img/${selectedImage}` });
      setCourse(prevCourse => ({ ...prevCourse, image_url: `/img/${selectedImage}` }));
    } catch (error) {
      console.error('Error updating course image:', error);
      setError('Failed to update course image. Please try again.');
    }
  };

  const refreshCourseLessons = async () => {
    try {
      const response = await api.get(`/courses/${id}/lessons`);
      setCourseLessons(response.data);
    } catch (error) {
      console.error('Error fetching course lessons:', error);
      setError('Failed to refresh course lessons. Please try again.');
    }
  };

  const handleRemoveLesson = async (lessonId, event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      await api.delete(`/courses/${id}/lessons/${lessonId}`);
      setCourseLessons(prevLessons => prevLessons.filter(lesson => lesson.id !== lessonId));
    } catch (error) {
      console.error('Error removing lesson from course:', error);
      setError('Failed to remove lesson from course. Please try again.');
    }
  };

  const handleEnrollment = async () => {
    try {
      const response = await api.post('/orders', { course_id: id });
      setEnrollmentMessage({
        type: 'success',
        text: 'Zamówienie zostało złożone. Oczekuj na zatwierdzenie przez nauczyciela.'
      });
    } catch (error) {
      console.error('Error placing order:', error);
      if (error.response && error.response.status === 400) {
        setEnrollmentMessage({
          type: 'warning',
          text: error.response.data.error || 'Nie można złożyć zamówienia. Sprawdź, czy nie jesteś już zapisany na ten kurs.'
        });
      } else {
        setEnrollmentMessage({
          type: 'danger',
          text: 'Wystąpił błąd podczas składania zamówienia. Spróbuj ponownie później.'
        });
      }
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (!course) {
    return <div className="alert alert-warning">Course not found.</div>;
  }

  return (
    <div>
	  {/* <ParallaxComponent> */}
        <h2 className="text-dark">{course.title}</h2>
	  {/* </ParallaxComponent> */}
      <div className="container-fluid mt-4">
        {userRole === 'student' && (
          <div className="mb-3">
            <button className="btn btn-primary btn-lg" onClick={handleEnrollment}>
              Zapisz się na kurs
            </button>
            {enrollmentMessage && (
              <div className={`alert alert-${enrollmentMessage.type} mt-2`}>
                {enrollmentMessage.text}
              </div>
            )}
          </div>
        )}
        <div className="row border p-4">
          <div className="col-md-3">
            {userRole === 'teacher' && (
              <button className="btn btn-primary btn-sm mb-3 w-100" onClick={() => setShowImageSelector(true)}>
                Zmień grafikę
              </button>
            )}
            {course.image_url && (
              <img src={course.image_url} className="img-fluid mb-3" alt={course.title} />
            )}
          </div>
          <div className="col-md-5">
            {userRole === 'teacher' && (
              <div className="mb-3">
                <Link to={`/courses/${id}/edit`} className="btn btn-secondary w-100">Edytuj kurs</Link>
              </div>
            )}
            <div
              className="ql-editor bg-light p-3 rounded"
              dangerouslySetInnerHTML={{ __html: course.description }}
            />
          </div>
          <div className="col-md-4">
            {userRole === 'teacher' && (
              <button className="btn btn-primary btn-sm mb-3 w-100" onClick={() => setShowLessonSelector(true)}>
                Dodaj lekcje
              </button>
            )}
            <h3>Lekcje</h3>
	  {/*
            <ul className="list-group">
              {courseLessons.map((lesson) => (
                <li key={lesson.id} className="list-group-item">
                  <Link
                    to={`/lessons/${lesson.id}`}
                    className="d-flex justify-content-between align-items-center text-decoration-none"
                  >
                    <span>{lesson.title}</span>
                    {userRole === 'teacher' && (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={(e) => handleRemoveLesson(lesson.id, e)}
                      >
                        Usuń
                      </button>
                    )}
                  </Link>
                </li>
              ))}
            </ul>*/}
	  <ul className="list-group">
  {courseLessons.map((lesson) => (
    <li key={lesson.id} className="list-group-item">
      {userRole === 'teacher' ? (
        <Link
          to={`/lessons/${lesson.id}`}
          className="d-flex justify-content-between align-items-center text-decoration-none"
        >
          <span>{lesson.title}</span>
          <button
            className="btn btn-danger btn-sm"
            onClick={(e) => handleRemoveLesson(lesson.id, e)}
          >
            Usuń
          </button>
        </Link>
      ) : (
        <span className="d-flex justify-content-between align-items-center">
          {lesson.title}
        </span>
      )}
    </li>
  ))}
</ul>

          </div>
        </div>

        <ImageSelector
          show={showImageSelector}
          onHide={() => setShowImageSelector(false)}
          onSelect={handleImageChange}
        />
        <LessonSelector
          show={showLessonSelector}
          onHide={() => {
            setShowLessonSelector(false);
            refreshCourseLessons();
          }}
          courseId={id}
        />
      </div>
    </div>
  );
};

export default CourseDetails;
