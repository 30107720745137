import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../services/api';
import LessonForm from './LessonForm';

const EditLesson = () => {
  const [lesson, setLesson] = useState({ title: '', content: '', image_url: '' });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const response = await api.get(`/lessons/${id}`);
        setLesson(response.data);
      } catch (error) {
        console.error('Failed to fetch lesson:', error);
      }
    };
    fetchLesson();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLesson(prevLesson => ({ ...prevLesson, [name]: value }));
  };

  const handleContentChange = (content) => {
    setLesson(prevLesson => ({ ...prevLesson, content: content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put(`/lessons/${id}`, lesson);
      navigate(`/lessons/${id}`);
    } catch (error) {
      console.error('Failed to update lesson:', error);
    }
  };

  return (
    <div className="container mt-4 bg-light">
      <LessonForm
        lesson={lesson}
        handleChange={handleChange}
        handleContentChange={handleContentChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default EditLesson;
