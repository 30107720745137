import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import axios from 'axios';

const VideoQuizCreator = ({ initialQuiz, onSave, onCancel, isNameUnique }) => {
  const [quizId, setQuizId] = useState(null);
  const [quizName, setQuizName] = useState('');
  const [selectedVideo, setSelectedVideo] = useState('');
  const [availableVideos, setAvailableVideos] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchAvailableVideos();
    if (initialQuiz) {
      setQuizId(initialQuiz.id || null);
      setQuizName(initialQuiz.name || '');
      setSelectedVideo(initialQuiz.data?.videoFile || '');
    }
  }, [initialQuiz]);

  const fetchAvailableVideos = async () => {
    try {
      const response = await axios.get('https://kursy.glon.org/quiz/api?action=video');
      setAvailableVideos(Object.values(response.data));
    } catch (error) {
      console.error('Error fetching available videos:', error);
      setError('Nie udało się pobrać listy dostępnych plików wideo.');
    }
  };

  const handleSave = () => {
    setError('');

    if (!quizName.trim()) {
      setError("Nazwa jest wymagana.");
      return;
    }

    if (!isNameUnique(quizName, quizId)) {
      setError("Quiz o takiej nazwie już istnieje. Wybierz inną nazwę.");
      return;
    }

    if (!selectedVideo) {
      setError("Wybór pliku wideo jest wymagany.");
      return;
    }

    const quizData = {
      id: quizId,
      type: "video",
      name: quizName,
      data: {
        videoFile: selectedVideo
      }
    };

    onSave(quizData);
  };

  return (
    <Container>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Nazwa quizu</Form.Label>
          <Form.Control
            type="text"
            value={quizName}
            onChange={(e) => setQuizName(e.target.value)}
            placeholder="Wpisz nazwę"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Wybierz plik wideo</Form.Label>
          <Form.Control
            as="select"
            value={selectedVideo}
            onChange={(e) => setSelectedVideo(e.target.value)}
            required
          >
            <option value="">Wybierz plik wideo</option>
            {availableVideos.map((video, index) => (
              <option key={index} value={video}>
                {video}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Button variant="primary" onClick={handleSave} className="me-2">
          Zapisz
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          Anuluj
        </Button>
      </Form>
    </Container>
  );
};

export default VideoQuizCreator;
