import React, { useState, useEffect } from 'react';
import api from '../services/api';
import LoadingSpinner from './LoadingSpinner';
import UserOrders from './UserOrders';

const UserProfile = () => {
  const [profileData, setProfileData] = useState(null);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profileResponse, ordersResponse] = await Promise.all([
          api.get('/user'),
          api.get('/orders')
        ]);
        setProfileData(profileResponse.data);
        setOrders(ordersResponse.data);
      } catch (error) {
        setError('Nie udało się pobrać danych. Spróbuj ponownie później.');
        console.error('Błąd podczas pobierania danych:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="container mt-4">
      <div className="card mb-4">
        <div className="card-body">
          <h2 className="card-title">Profil Użytkownika</h2>
          <p><strong>Imię:</strong> {profileData.name}</p>
          <p><strong>Email:</strong> {profileData.email}</p>
          <p><strong>Rola:</strong> {profileData.role}</p>
          <p><strong>ID użytkownika:</strong> {profileData.id}</p>
        </div>
      </div>

  {profileData.role === 'student' && (
      <UserOrders orders={orders} /> )}
    </div>
  );
};

export default UserProfile;
