import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import api from '../services/api';

const ImageManager = () => {
  const [images, setImages] = useState([]);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/images');
      if (response.data.success && typeof response.data.images === 'object') {
        const imagesArray = Object.values(response.data.images);
        setImages(imagesArray);
      } else {
        console.error('Unexpected API response format:', response.data);
        setImages([]);
        setMessage('Error: Unexpected data format received from server.');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
      setMessage('Failed to fetch images. Please try again.');
      setImages([]);
    } finally {
      setIsLoading(false);
    }
  };

  const sanitizeFileName = (fileName) => {
    return fileName.replace(/\s+/g, '_');
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const sanitizedFileName = sanitizeFileName(file.name);
      const formData = new FormData();
      formData.append('image', file, sanitizedFileName);

      setUploadProgress(prev => ({ ...prev, [sanitizedFileName]: 0 }));

      api.post('/images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(prev => ({ ...prev, [sanitizedFileName]: percentCompleted }));
        },
      })
      .then(response => {
        setMessage(`${sanitizedFileName} przesłane`);
        fetchImages();
      })
      .catch(error => {
        console.error('Error uploading image:', error);
        setMessage(`Nie udało się przesłać ${sanitizedFileName}. Spróbuj ponownie.`);
      })
      .finally(() => {
        // Remove progress bar after a short delay
        setTimeout(() => {
          setUploadProgress(prev => {
            const newProgress = { ...prev };
            delete newProgress[sanitizedFileName];
            return newProgress;
          });
        }, 1000); // 1 second delay
      });
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true
  });

  const handleDelete = async (fileName) => {
    setIsLoading(true);
    try {
      const response = await api.delete(`/images/${fileName}`);
      setMessage(response.data.message);
      fetchImages();
    } catch (error) {
      console.error('Error deleting image:', error);
      setMessage('Nie udało się usunąć. Spróbuj ponownie.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Grafika</h2>
      {message && (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          {message}
          <button type="button" className="btn-close" onClick={() => setMessage(null)}></button>
        </div>
      )}
      <div {...getRootProps()} className="dropzone mb-3 p-4 border-dark border rounded bg-secondary-subtle text-center">
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
	<p>Przeciągnij pliki tutaj, lub <button onClick={open} className="btn btn-outline-dark btn-sml">kliknij i wybierz</button></p>

        }
      </div>
      {Object.entries(uploadProgress).map(([fileName, progress]) => (
        <div key={fileName} className="mb-2">
          <strong>{fileName}</strong>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{width: `${progress}%`}}
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {progress}%
            </div>
          </div>
        </div>
      ))}
      {isLoading && <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>}
      <div className="row">
        {images.map((image, index) => (
          <div key={index} className="col-md-2 mb-3">
            <div className="card">
              <img src={`/img/${image}`} className="card-img-top" alt={image} style={{height: '200px', objectFit: 'cover'}} />
              <div className="card-body">
                <p className="visually-hidden card-title">{image}</p>
                <button onClick={() => handleDelete(image)} className="btn btn-sml btn-danger" disabled={isLoading}>
                  {isLoading ? 'Usuń' : 'Usuń'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageManager;
