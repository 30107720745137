import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import CourseForm from './CourseForm';

const CreateCourse = () => {
  const [course, setCourse] = useState({ title: '', description: '', image_url: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourse(prevCourse => ({ ...prevCourse, [name]: value }));
  };

  const handleDescriptionChange = (content) => {
    setCourse(prevCourse => ({ ...prevCourse, description: content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/courses', course);
      navigate(`/courses/${response.data.id}`);
    } catch (error) {
      console.error('Failed to create course:', error);
    }
  };

  return (
    <div className="container mt-4 bg-light">
      <CourseForm
        course={course}
        handleChange={handleChange}
        handleDescriptionChange={handleDescriptionChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default CreateCourse;
