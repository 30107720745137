import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
	  <>
	  <hr/>
    <footer className="bg-light pt-4">
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <h5>Platforma Edukacyjna</h5>
            <p>Twoje centrum nauki online</p>
          </div>
          <div className="col-md-3">
            <h5>Linki</h5>
            <ul className="list-unstyled">
              <li><Link to="/">Strona główna</Link></li>
              <li><Link to="/help">Pomoc</Link></li>
              <li><Link to="/contact">Kontakt</Link></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Prawne</h5>
            <ul className="list-unstyled">
              <li><Link to="/privacy-policy">Polityka prywatności</Link></li>
              <li><Link to="/terms">Warunki użytkowania</Link></li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="text-center">
          <p>&copy; {new Date().getFullYear()} Platforma Edukacyjna. Wszelkie prawa zastrzeżone.</p>
        </div>
      </div>
    </footer></>
  );
};

export default Footer;
