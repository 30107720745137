import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Table, Button, Form, Alert, Modal, Dropdown } from 'react-bootstrap';
import api, { quizapi } from '../services/api';
import TranslationQuizCreator from './TranslationQuizCreator';
import CategoryQuizCreator from './CategoryQuizCreator';
import SentenceQuizCreator from './SentenceQuizCreator';
import MultipleChoiceQuizCreator from './MultipleChoiceQuizCreator';
import VideoQuizCreator from './VideoQuizCreator';
import TranslationQuizGame from './TranslationQuizGame';
import CategoryQuizGame from './CategoryQuizGame';
import SentenceQuizGame from './SentenceQuizGame';
import MultipleChoiceQuizGame from './MultipleChoiceQuizGame';
import VideoQuizGame from './VideoQuizGame';

const QuizManager = () => {
  const [user, setUser] = useState(null);
  const [quizzes, setQuizzes] = useState([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);
  const [newQuizType, setNewQuizType] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [editingQuiz, setEditingQuiz] = useState(null);
  const [playingQuiz, setPlayingQuiz] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showGameModal, setShowGameModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterType, setFilterType] = useState('all');

  const fetchUser = useCallback(async () => {
    try {
      const response = await api.get('/user');
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user:', error);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const fetchQuizzes = useCallback(async () => {
    // Wyłączanie autoryzaji if (user?.role !== 'teacher') return;

    setIsLoading(true);
    try {
      const response = await quizapi.get('/');
      setQuizzes(response.data);
      setFilteredQuizzes(response.data);
    } catch (error) {
      console.error('Error fetching quizzes:', error);
      setError("Nie udało się pobrać listy quizów.");
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    // wyłączenie autoryzacji if (user) {
    if (true) {
      fetchQuizzes();
    }
  }, [user, fetchQuizzes]);

  useEffect(() => {
    if (filterType === 'all') {
      setFilteredQuizzes(quizzes);
    } else {
      setFilteredQuizzes(quizzes.filter(quiz => quiz.type === filterType));
    }
  }, [filterType, quizzes]);

  const fetchQuizDetails = async (quizId) => {
    try {
      const response = await quizapi.get(`/?id=${quizId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching quiz details:', error);
      setError("Nie udało się pobrać szczegółów quizu.");
      return null;
    }
  };

  const handleEdit = async (quiz) => {
    const quizDetails = await fetchQuizDetails(quiz.id);
    if (quizDetails) {
      setEditingQuiz(quizDetails);
      setShowEditModal(true);
    }
  };

  const handlePlay = async (quiz) => {
    const quizDetails = await fetchQuizDetails(quiz.id);
    if (quizDetails) {
      setPlayingQuiz(quizDetails);
      setShowGameModal(true);
    }
  };

  const handleCreateNew = () => {
    if (newQuizType) {
      setEditingQuiz({ type: newQuizType, isNew: true });
      setShowEditModal(true);
    }
  };

  const isNameUnique = (name, currentQuizId = null) => {
    return !quizzes.some(quiz => quiz.name === name && quiz.id !== currentQuizId);
  };

  const handleSaveQuiz = async (quizData) => {
    if (!isNameUnique(quizData.name, quizData.id)) {
      setError('Takiej nazwa już istnieje. Wybierz inną.');
      return;
    }

    try {
      let response;
      if (quizData.id) {
        response = await quizapi.put(`/?id=${quizData.id}`, quizData);
      } else {
        response = await quizapi.post('/', quizData);
      }
      setSuccess('Zapisane!');
      setEditingQuiz(null);
      setShowEditModal(false);
      await fetchQuizzes();
    } catch (error) {
      console.error('Error saving quiz:', error);
      setError('Wystąpił błąd podczas zapisywania.');
    }
  };

  const getQuizTypeName = (type) => {
    switch (type) {
      case 'all': return 'Wszystkie';
      case 'translation': return 'Tłumaczenia';
      case 'category': return 'Kategorie';
      case 'sentence': return 'Uzupełnianie zdań';
      case 'multipleChoice': return 'Test wyboru';
      case 'video': return 'Film';
      default: return 'Nieznany typ';
    }
  };

  const quizTypes = ['all', 'translation', 'category', 'sentence', 'multipleChoice', 'video'];

  if (isLoading) {
    return <p>Ładowanie...</p>;
  }

  // Wyłączenie autoryzacji if (!user || user.role !== 'teacher') {
  if ( false ) {
    return  ( <Container fluid>
	    	<p>Nie masz uprawnień do wyświetlenia tej strony.</p>
	    </Container> );
  }

  return (
  <Container fluid>
      <Row>
        {/* Sidebar */}
        <Col md={3} className="bg-light p-4">
          <h2 className="mb-4">Testy i filmy</h2>
          <Form.Group className="mb-4">
            <Form.Label><strong>Filtr</strong></Form.Label>
            <Dropdown className="d-block">
              <Dropdown.Toggle variant="primary" id="dropdown-filter" className="w-100">
                {getQuizTypeName(filterType)}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {quizTypes.map((type) => (
                  <Dropdown.Item key={type} onClick={() => setFilterType(type)}>
                    {getQuizTypeName(type)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <h3 className="mt-5 mb-3">Nowy test</h3>
          <Form.Group className="mb-4">
            <Form.Label><strong>Wybierz typ</strong></Form.Label>
            <Dropdown className="d-block">
              <Dropdown.Toggle variant="secondary" id="dropdown-new-quiz" className="w-100">
                {newQuizType ? getQuizTypeName(newQuizType) : 'Wybierz typ'}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {quizTypes.filter(type => type !== 'all').map((type) => (
                  <Dropdown.Item key={type} onClick={() => setNewQuizType(type)}>
                    {getQuizTypeName(type)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Button 
            onClick={handleCreateNew} 
            disabled={!newQuizType}
            size="lg"
            className="w-100"
          >
            Nowy 
          </Button>
        </Col>

        {/* Main content */}
        <Col md={9} className="p-4">
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}

	  {/*<h2 className="mb-4"></h2>*/}
          {isLoading ? (
            <p>Ładowanie ...</p>
          ) : (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Nazwa</th>
                  <th>Typ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredQuizzes.map(quiz => (
                  <tr key={quiz.id}>
                    <td>{quiz.name}</td>
                    <td>{getQuizTypeName(quiz.type)}</td>
                    <td>
                      <Button variant="primary" onClick={() => handleEdit(quiz)} className="me-2">
                        Edytuj
                      </Button>
                      <Button variant="success" onClick={() => handlePlay(quiz)}>
                        Wykonaj
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>


      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} backdrop="static" keyboard={false} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>{editingQuiz?.id ? 'Edycja' : 'Tworzenie nowego testu'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingQuiz && editingQuiz.type === 'translation' && (
            <TranslationQuizCreator
              initialQuiz={editingQuiz}
              onSave={handleSaveQuiz}
              onCancel={() => setShowEditModal(false)}
              isNameUnique={isNameUnique}
            />
          )}
          {editingQuiz && editingQuiz.type === 'category' && (
            <CategoryQuizCreator
              initialQuiz={editingQuiz}
              onSave={handleSaveQuiz}
              onCancel={() => setShowEditModal(false)}
              isNameUnique={isNameUnique}
            />
          )}
          {editingQuiz && editingQuiz.type === 'sentence' && (
            <SentenceQuizCreator
              initialQuiz={editingQuiz}
              onSave={handleSaveQuiz}
              onCancel={() => setShowEditModal(false)}
              isNameUnique={isNameUnique}
            />
          )}
          {editingQuiz && editingQuiz.type === 'multipleChoice' && (
            <MultipleChoiceQuizCreator
              initialQuiz={editingQuiz}
              onSave={handleSaveQuiz}
              onCancel={() => setShowEditModal(false)}
              isNameUnique={isNameUnique}
            />
          )}
          {editingQuiz && editingQuiz.type === 'video' && (
            <VideoQuizCreator
              initialQuiz={editingQuiz}
              onSave={handleSaveQuiz}
              onCancel={() => setShowEditModal(false)}
              isNameUnique={isNameUnique}
            />
          )}
        </Modal.Body>
      </Modal>

      {/* Game Modal */}
      <Modal show={showGameModal} onHide={() => setShowGameModal(false)} backdrop="static" keyboard={false} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>Quiz: {playingQuiz?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {playingQuiz && playingQuiz.type === 'translation' && (
            <TranslationQuizGame
              quiz={playingQuiz}
              onClose={() => setShowGameModal(false)}
            />
          )}
          {playingQuiz && playingQuiz.type === 'category' && (
            <CategoryQuizGame
              quiz={playingQuiz}
              onClose={() => setShowGameModal(false)}
            />
          )}
          {playingQuiz && playingQuiz.type === 'sentence' && (
            <SentenceQuizGame
              quiz={playingQuiz}
              onClose={() => setShowGameModal(false)}
            />
          )}
          {playingQuiz && playingQuiz.type === 'multipleChoice' && (
            <MultipleChoiceQuizGame
              quiz={playingQuiz}
              onClose={() => setShowGameModal(false)}
            />
          )}
          {playingQuiz && playingQuiz.type === 'video' && (
            <VideoQuizGame
              quiz={playingQuiz}
              onClose={() => setShowGameModal(false)}
            />
          )}
        </Modal.Body>
      </Modal>
  </Container>
  );
};

export default QuizManager;
