import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../services/api';
import CourseForm from './CourseForm';

const EditCourse = () => {
  const [course, setCourse] = useState({ title: '', description: '', image_url: '' });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await api.get(`/courses/${id}`);
        setCourse(response.data);
      } catch (error) {
        console.error('Failed to fetch course:', error);
      }
    };
    fetchCourse();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourse(prevCourse => ({ ...prevCourse, [name]: value }));
  };

  const handleDescriptionChange = (content) => {
    setCourse(prevCourse => ({ ...prevCourse, description: content }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put(`/courses/${id}`, course);
      navigate(`/courses/${id}`);
    } catch (error) {
      console.error('Failed to update course:', error);
    }
  };

  return (
    <div className="container mt-4 bg-light">
      <CourseForm
        course={course}
        handleChange={handleChange}
        handleDescriptionChange={handleDescriptionChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default EditCourse;
