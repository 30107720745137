import axios from 'axios';

const api = axios.create({
  baseURL: 'https://kursy.glon.org/api',
});

const quizapi = axios.create({
  baseURL: 'https://kursy.glon.org/quiz/api',
});

export default api;
export { quizapi };
