import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

const CategoryQuizCreator = ({ initialQuiz, onSave, onCancel, isNameUnique }) => {
  const [quizId, setQuizId] = useState(null);
  const [quizName, setQuizName] = useState('');
  const [categories, setCategories] = useState([{ name: '', words: '' }]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (initialQuiz) {
      setQuizId(initialQuiz.id || null);
      setQuizName(initialQuiz.name || '');
      if (initialQuiz.data && initialQuiz.data.length > 0) {
        setCategories(initialQuiz.data.map(cat => ({
          name: cat.name,
          words: cat.words.join('\n')
        })));
      } else {
        setCategories([{ name: '', words: '' }]);
      }
    }
  }, [initialQuiz]);

  const handleCategoryChange = (index, field, value) => {
    const newCategories = [...categories];
    newCategories[index][field] = value;
    setCategories(newCategories);
  };

  const addCategory = () => {
    setCategories([...categories, { name: '', words: '' }]);
  };

  const removeCategory = (index) => {
    if (categories.length > 1) {
      setCategories(categories.filter((_, i) => i !== index));
    }
  };

  const handleSave = () => {
    setError('');

    if (!quizName.trim()) {
      setError("Nazwa jest wymagana.");
      return;
    }

    if (!isNameUnique(quizName, quizId)) {
      setError("Jest już taka nazwa. Wybierz inną.");
      return;
    }

    const validCategories = categories.filter(cat => cat.name.trim() && cat.words.trim());
    if (validCategories.length < 2) {
      setError("Test musi zawierać co najmniej dwie kategorie.");
      return;
    }

    const quizData = {
      id: quizId,
      type: "category",
      name: quizName,
      data: validCategories.map(cat => ({
        name: cat.name.trim(),
        words: cat.words.split('\n').filter(word => word.trim() !== '')
      }))
    };

    onSave(quizData);
  };

  return (
    <Container>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form className="bg-secondary-subtle p-3">
        <Form.Group><Row className="gy-1"><Col md={4}>
          <Form.Label className="Display-5">Nazwa testu</Form.Label>
          <Form.Control
            type="text"
            value={quizName}
            onChange={(e) => setQuizName(e.target.value)}
            placeholder="Wpisz nazwę testu"
            required
          /></Col>
	  <Col>
	  <Button onClick={addCategory} className="mx-2 mb-3">Dodaj kategorię</Button>
	  </Col>
	  </Row><hr/>
        </Form.Group>
        {categories.map((category, index) => (
          <Row key={index} className="my-2">
            <Col md={3}>
              <Form.Control
                type="text"
                placeholder="Nazwa kategorii"
                value={category.name}
                onChange={(e) => handleCategoryChange(index, 'name', e.target.value)}
                required
              />
            </Col>
            <Col md={6}>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Wpisz słowa lub frazy, każde w nowej linii"
                value={category.words}
                onChange={(e) => handleCategoryChange(index, 'words', e.target.value)}
                required
              />
            </Col>
            <Col md={3}>
              <Button className="mx-2" variant="danger" onClick={() => removeCategory(index)}>Usuń kategorię</Button>
            </Col>
          </Row>
        ))}
	  {/*<Button onClick={addCategory} className="mx-2 mb-3">Dodaj kategorię</Button>*/}
        <div><hr/>
          <Button className="mx-2" variant="primary" onClick={handleSave}>
            Zapisz test
          </Button>
          <Button variant="secondary" onClick={onCancel} className="mx-2 ml-2">
            Anuluj
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default CategoryQuizCreator;
