import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Container, Button, Ratio, Alert } from 'react-bootstrap';
import axios from 'axios';

const API_URL = 'https://kursy.glon.org/quiz/api/';

const VideoQuizGame = ({ quizId, onClose }) => {
  const [quiz, setQuiz] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const fetchQuiz = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}?id=${quizId}`);
        setQuiz(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching quiz:', error);
        setError('Nie udało się pobrać danych quizu.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuiz();
  }, [quizId]);

  if (isLoading) {
    return <Alert variant="info">Ładowanie quizu wideo...</Alert>;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  if (!quiz || !quiz.data || !quiz.data.videoFile) {
    return <Alert variant="warning">Brak danych quizu wideo lub nieprawidłowe dane.</Alert>;
  }

  const videoUrl = `https://kursy.glon.org/video/${quiz.data.videoFile}`;

  return (
    <Container className="bg-secondary">
      <Row>
        <Col>
          <h2>{quiz.name}</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <div className="video-container mb-3">
            <Ratio aspectRatio="16x9">
              <video
                ref={videoRef}
                controls
                style={{ width: '100%', height: 'auto' }}
                src={videoUrl}
              >
                Twoja przeglądarka nie obsługuje odtwarzania wideo.
              </video>
            </Ratio>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={onClose} className="mt-3">
            Zakończ
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default VideoQuizGame;
