import React, { useState, useRef, useEffect } from 'react';

const VideoPlayer = ({ src, title }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const progressBarRef = useRef(null);

  const startVideo = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  const stopVideo = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  const updateProgress = () => {
    if (videoRef.current) {
      const progressPercent = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progressPercent);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', updateProgress);
    return () => {
      videoElement.removeEventListener('timeupdate', updateProgress);
    };
  }, []);

  const handleProgressClick = (e) => {
    const progressBar = progressBarRef.current;
    const clickPosition = (e.clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth;
    const newTime = clickPosition * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
  };

  return (
    <div className="video-player">
      <video
        ref={videoRef}
        className="w-100"
      >
        <source src={`/video/${src}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div
        className="progress mt-2"
        style={{ height: '4px', cursor: 'pointer' }}
        onClick={handleProgressClick}
        ref={progressBarRef}
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="controls mt-2 d-flex justify-content-between align-items-center">
        <div>
          <button
            className="btn btn-primary me-2"
            onClick={startVideo}
            disabled={isPlaying}
          >
            Start
          </button>
          <button
            className="btn btn-danger"
            onClick={stopVideo}
            disabled={!isPlaying}
          >
            Stop
          </button>
        </div>
        <span className="video-title">{title}</span>
      </div>
    </div>
  );
};

export default VideoPlayer;
