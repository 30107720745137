import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Home = () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <div className="home">
      {/* Hero Section with background image */}
      <div className="py-5 text-center text-white mb-5"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url('/img/pb007_folk_band_with_guitar_sax_and_accordion_and_bass_gutar_va_d62f4906-f047-4bf9-adcc-964a3acdd667.jpg')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
        <div className="py-5" style={{backgroundColor: 'rgba(0,0,0,0.0)'}}>
          <h1 className="display-4 fw-bold">Witaj w Platformie Edukacyjnej</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4 mx-4">Odkryj nowe możliwości nauki, rozwijaj swoje umiejętności i osiągaj cele edukacyjne z naszą innowacyjną platformą.</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center px-4">
              {isAuthenticated ? (
                <>
                  <Link to="/courses" className="btn btn-primary btn-lg px-4 gap-3">Rozpocznij naukę</Link>
                  <Link to="/profile" className="btn btn-outline-light btn-lg px-4">Mój profil</Link>
                </>
              ) : (
                <>
                  <Link to="/login" className="btn btn-primary btn-lg px-4 gap-3">Zaloguj się</Link>
                  <a href="/register/register.html" className="btn btn-outline-light btn-lg px-4">Zarejestruj się</a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Call-to-Action Buttons with images */}
      <div className="container">
        <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
          <div className="col">
            <div className="card h-100 shadow-sm">
              <img src="/img/pb007_No_Prompt_e57ddbf4-d26f-464b-acb4-45976fc940fc.jpg" className="card-img-top" alt="Przeglądaj kursy" />
              <div className="card-body">
                <h2 className="card-title">Przeglądaj kursy</h2>
                <p className="card-text">Odkryj szeroki wybór kursów dostosowanych do Twoich potrzeb i zainteresowań.</p>
                <Link to={isAuthenticated ? "/courses" : "/login"} className="btn btn-primary">
                  {isAuthenticated ? "Zobacz kursy" : "Zaloguj się, aby zobaczyć kursy"}
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 shadow-sm">
              <img src="/img/pb007_No_Prompt_7a80a3aa-6eb2-4fc1-a5ed-b96f71234436.jpg" className="card-img-top" alt="Przejdź do lekcji" />
              <div className="card-body">
                <h2 className="card-title">Przejdź do lekcji</h2>
                <p className="card-text">Kontynuuj naukę od miejsca, w którym skończyłeś lub rozpocznij nową lekcję.</p>
                <Link to={isAuthenticated ? "/lessons" : "/login"} className="btn btn-primary">
                  {isAuthenticated ? "Moje lekcje" : "Zaloguj się, aby zobaczyć lekcje"}
                </Link>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100 shadow-sm">
              <img src="/img/pb007_null_af6b8279-8949-4f44-b6e4-b5c34dc493dc.jpg" className="card-img-top" alt="Rozwiąż testy" />
              <div className="card-body">
                <h2 className="card-title">Rozwiąż testy</h2>
                <p className="card-text">Sprawdź swoją wiedzę i postępy, rozwiązując interaktywne testy i quizy.</p>
                <Link to={isAuthenticated ? "/tests" : "/login"} className="btn btn-primary">
                  {isAuthenticated ? "Rozpocznij test" : "Zaloguj się, aby rozpocząć test"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
