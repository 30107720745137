import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';
import LoadingSpinner from './LoadingSpinner';

const Courses = ({user}) => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const parallaxRefs = useRef([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/courses');
        setCourses(response.data);
      } catch (error) {
        console.error('Failed to fetch courses:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCourses();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      parallaxRefs.current.forEach((ref, index) => {
        if (ref) {
          const scrollY = window.scrollY;
          const offset = ref.offsetTop;
          const height = ref.offsetHeight;

          if (scrollY > offset - window.innerHeight && scrollY < offset + height) {
            const parallaxSpeed = 0.1;
            const yPos = (scrollY - offset) * parallaxSpeed;
            ref.style.backgroundPositionY = `${yPos}px`;
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [courses]);

  const handleDeleteCourse = async (courseId) => {
    if (window.confirm('Czy na pewno chcesz usunąć ten kurs?')) {
      try {
        await api.delete(`/courses/${courseId}`);
        setCourses(courses.filter(course => course.id !== courseId));
      } catch (error) {
        console.error('Failed to delete course:', error);
      }
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Kursy</h2>
        {user && user.role === 'teacher' && (
          <Link to="/courses/create" className="btn btn-primary">Nowy kurs</Link>
        )}
      </div>
      <div className="list-group">
        {courses.map(course => (
          <div key={course.id} className="list-group-item">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h5 className="mb-0">{course.title}</h5>
              {user && user.role === 'teacher' && (
                <button
                  onClick={() => handleDeleteCourse(course.id)}
                  className="btn btn-danger btn-sm"
                >
                  Usuń kurs
                </button>
              )}
            </div>
            <Link to={`/courses/${course.id}`} className="text-decoration-none">
              <div className="row">
                {course.image_url && (
                  <div className="col-md-3">
                    <img
                      src={course.image_url}
                      className="img-fluid rounded"
                      alt={course.title}
                    />
                  </div>
                )}
                <div className={course.image_url ? "col-md-9" : "col-md-12"}>
                  <div
                    className="ql-editor bg-light p-3 rounded"
                    dangerouslySetInnerHTML={{ __html: course.description }}
                  />
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Courses;
